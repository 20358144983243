import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = "goals";

export async function createGoal(data) {
  return await handleApi(axiosClient.post(`${BASE}`, data));
}

export async function getGoals(id) {
  return await handleApi(axiosClient.get(`${BASE}`));
}

export async function getGoalById(id) {
  return await handleApi(axiosClient.get(`${BASE}/${id}`));
}

export async function getThreshold(locationType, value) {
  return await handleApi(
    axiosClient.get(
      `${BASE}/thresholds?locationType=${locationType}&country=${value}`
    )
  );
}

export async function deleteGoalById(id) {
  return await handleApi(axiosClient.delete(`${BASE}/${id}`));
}

export async function getTimelineById(id, from, to) {
  return await handleApi(
    axiosClient.get(`${BASE}/${id}/timeline?from=${from}&to=${to}`)
  );
}

export async function updateGoal(id, data) {
  return await handleApi(axiosClient.patch(`${BASE}/${id}`, data));
}

export async function allCities(countryCode = "", state = "") {
  return await handleApi(
    axiosClient.get(
      `${BASE}/all-cities${
        countryCode && "?countryCode=" + countryCode + "&stateCode=" + state
      }`
    )
  );
}

export async function allStates(countryCode = "") {
  return await handleApi(
    axiosClient.get(
      `${BASE}/all-states${countryCode && "?countryCode=" + countryCode}`
    )
  );
}

export async function allCountries() {
  return await handleApi(axiosClient.get(`${BASE}/all-countries`));
}
