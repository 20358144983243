const DigitalNomad = ({ className }) => {
  return (
    <svg
      className={className}
      width="62"
      height="60"
      viewBox="0 0 62 64"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.3333 63.1368H15.3164C11.3232 63.1368 9.4069 61.7522 8.29424 57.9197C5.89584 49.822 3.59634 41.6872 1.28447 33.5771C0.901221 32.2048 0.616874 30.7089 2.43422 30.1897C4.25157 29.6705 4.9068 30.9809 5.29005 32.5139C7.29284 39.5979 9.33273 46.6571 11.3602 53.7163C12.4482 57.5241 14.3768 59.0076 18.2958 59.0076H56.967C57.5805 58.9706 58.1956 58.9706 58.8091 59.0076C60.0454 59.156 61.0591 59.7123 60.9973 61.1835C60.9355 62.6547 59.9588 63.1245 58.636 63.1245H36.3828L36.3333 63.1368Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M15.4033 56.0036C15.8855 55.6698 16.3553 55.3112 16.8498 55.0022C19.848 53.1676 22.0794 50.3081 23.1301 46.9539C23.263 46.4434 23.5341 45.9794 23.9136 45.6129C24.2932 45.2465 24.7664 44.9918 25.2813 44.8769C29.8144 43.4428 34.3474 41.9387 38.8805 40.3645C39.9993 39.8626 41.078 39.2757 42.1072 38.6089C42.6264 39.2518 43.8133 40.0925 44.0853 41.1557C45.0125 44.8646 45.6183 48.7465 46.3477 52.5667C46.1217 52.6695 45.8855 52.7482 45.643 52.8016C42.1567 52.987 42.169 52.9746 40.8709 49.7108C40.0179 47.5721 39.1525 45.4456 38.2747 43.2698C37.2609 43.5541 36.1112 43.4058 36.6675 45.0129C36.7788 45.3344 36.173 46.0514 35.7527 46.4099C32.6125 49.138 29.4517 51.8414 26.2703 54.52C23.0683 57.2151 19.4213 57.4377 15.5888 56.5723L15.4033 56.0036Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M46.7184 13.2526C47.278 13.4217 47.8271 13.624 48.3626 13.8584C49.2183 14.237 49.9149 14.9024 50.3325 15.7397C50.75 16.577 50.8622 17.5338 50.6498 18.445C50.5004 19.3268 50.0484 20.1289 49.3715 20.7133C48.6945 21.2978 47.835 21.6279 46.9409 21.647C45.1854 21.7336 43.4175 21.647 41.699 21.647C40.8584 17.5549 40.6976 17.456 37.78 17.5178C34.8623 17.5796 35.3074 19.6195 35.1467 21.4987C32.9832 21.8572 31.0175 21.7954 29.707 19.7431C29.2256 19.083 28.9693 18.2854 28.976 17.4684C28.9826 16.6514 29.2519 15.8582 29.7441 15.2059C31.5244 12.7334 33.8486 13.3391 36.1728 14.3034C36.4572 9.43246 37.916 7.41731 41.118 7.28132C44.32 7.14532 46.2239 9.11103 46.7184 13.2526Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M8.15792 22.3146C4.44905 22.3146 3.27457 20.6209 4.74576 17.6167C5.35154 16.3804 4.74576 15.7128 4.21415 14.8845C1.32123 10.7553 4.21415 5.63705 7.92303 5.38979C9.3324 8.4187 9.56729 8.64123 12.6457 9.34592C12.0522 8.25798 11.6566 7.59038 11.3228 6.87334C10.989 6.15629 10.272 5.20434 10.4821 4.66038C10.6767 4.26771 10.9602 3.92581 11.31 3.66185C11.6598 3.39788 12.0663 3.21911 12.4973 3.13974C15.2171 2.96666 17.9617 3.01611 20.6815 3.13974C21.0759 3.12418 21.4693 3.19019 21.837 3.33363C22.2047 3.47707 22.5388 3.69486 22.8185 3.97337C23.0982 4.25188 23.3173 4.58511 23.4623 4.95221C23.6073 5.31932 23.6749 5.71239 23.661 6.10684C23.6844 6.50086 23.6295 6.89561 23.4997 7.26835C23.3698 7.64109 23.1675 7.98445 22.9043 8.27864C22.6411 8.57283 22.3224 8.81203 21.9663 8.98248C21.6103 9.15292 21.2241 9.25123 20.8299 9.27174C20.1499 9.34592 19.47 9.27174 18.7777 9.27174C17.3747 9.23991 15.9798 9.49175 14.6765 10.0121C13.3732 10.5325 12.1885 11.3108 11.1933 12.3002C10.1982 13.2896 9.41304 14.4698 8.88507 15.77C8.35711 17.0702 8.09717 18.4636 8.12083 19.8668C8.14556 20.7074 8.15792 21.511 8.15792 22.3146Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5001 28.051L10.1237 27.8161C10.2844 24.2803 10.0124 20.7569 10.7047 17.4189C11.5825 13.1661 15.4521 10.8295 20.5209 11.4971C20.8917 11.4971 21.3615 12.1029 21.5099 12.5232C21.8931 13.5988 22.128 14.7362 22.4248 15.8488L21.8808 15.7252C21.3278 16.7205 20.9356 17.797 20.7187 18.9148C20.558 23.7487 19.878 24.3545 15.2172 23.2295C14.5828 23.0491 13.9106 23.0491 13.2762 23.2295C13.6399 23.8712 13.9421 24.546 14.1787 25.2446C14.3503 26.1716 14.4577 27.1093 14.5001 28.051V28.051Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M41.439 27.8161V23.7734H44.7028C50.7606 23.7734 52.7881 21.7335 52.7387 15.7128C53.3432 15.6443 53.9554 15.6964 54.5396 15.8661C55.1239 16.0358 55.6687 16.3198 56.1424 16.7015C56.6162 17.0833 57.0095 17.5552 57.2995 18.0901C57.5896 18.6249 57.7706 19.212 57.8322 19.8173C57.8795 20.3304 58.0519 20.8241 58.3342 21.2552C58.6166 21.6862 59.0003 22.0415 59.4517 22.2899C59.9015 22.6481 60.2619 23.1059 60.5043 23.6273C60.7468 24.1487 60.8647 24.7193 60.8487 25.2941C60.7127 26.2336 59.4641 27.7048 58.6481 27.7666C52.9488 27.915 47.2495 27.8161 41.439 27.8161Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.53088 39.6968C11.0886 39.1775 12.3249 38.6954 13.7219 38.275C14.2054 38.2016 14.6972 38.2016 15.1807 38.275C15.0382 38.7549 14.8563 39.2222 14.6368 39.672C14.3647 40.0571 14.054 40.4134 13.7095 40.7353L15.1807 42.2435C18.3456 39.0292 21.288 35.9137 24.4035 32.9961C24.803 32.7238 25.2631 32.5534 25.7436 32.4995C26.2241 32.4456 26.7105 32.51 27.1604 32.687C28.3225 33.4535 27.9269 34.7022 26.9873 35.6541L26.8513 35.8025C23.81 38.8437 20.8429 41.9839 17.6904 44.8645C16.6711 45.737 15.4302 46.3103 14.1052 46.5211C13.7268 46.6249 13.3301 46.6429 12.944 46.5737C12.5578 46.5045 12.192 46.3499 11.8733 46.1212C11.5545 45.8925 11.2909 45.5955 11.1017 45.2519C10.9125 44.9083 10.8024 44.5267 10.7795 44.1351C10.4433 42.6343 10.0265 41.1527 9.53088 39.6968V39.6968Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M54.9525 1.00095C56.3319 1.03018 57.6436 1.60463 58.6005 2.5986C59.5573 3.59257 60.0816 4.92511 60.0584 6.30464C59.9991 7.64904 59.431 8.92065 58.4692 9.86183C57.5073 10.803 56.2237 11.3434 54.8783 11.3734C53.4988 11.3572 52.1813 10.7977 51.2116 9.81644C50.2419 8.83515 49.6981 7.51114 49.6982 6.13156C49.7014 5.44823 49.8404 4.77235 50.1071 4.14321C50.3738 3.51407 50.7629 2.94423 51.2518 2.46683C51.7407 1.98942 52.3197 1.61398 52.955 1.36232C53.5903 1.11067 54.2693 0.987833 54.9525 1.00095V1.00095Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.3635 42.7874C23.7899 41.7541 25.2992 40.8403 26.876 40.0552C29.7813 38.8189 32.7855 37.9412 35.7773 37.0263C36.0069 36.9889 36.2269 36.9063 36.4243 36.7831C36.6218 36.66 36.7927 36.4988 36.9273 36.309C37.0619 36.1192 37.1574 35.9046 37.2083 35.6775C37.2592 35.4504 37.2644 35.2155 37.2237 34.9864C37.1496 30.6223 37.2237 26.2459 37.2237 21.8818V19.7677H39.276C39.276 25.6277 39.276 31.4136 39.276 37.187C39.2249 37.4307 39.1179 37.6591 38.9634 37.8544C38.8089 38.0496 38.6112 38.2063 38.3859 38.3121C33.3541 40.0305 28.2977 41.6871 23.2537 43.3314C23.0929 43.3314 22.8704 43.3314 22.685 43.3314L22.3635 42.7874Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.7966 35.7036L8.61514 37.7929C8.42598 35.9309 8.42598 34.0545 8.61514 32.1925C8.81696 31.6831 9.14151 31.2315 9.55993 30.8778C9.97835 30.5241 10.4777 30.2792 11.0135 30.165C11.5529 30.2044 12.0764 30.365 12.5452 30.6347C13.0139 30.9044 13.4158 31.2763 13.721 31.7227C14.2681 32.9914 14.6304 34.332 14.7966 35.7036V35.7036Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.0276 48.4993C15.9466 49.3152 18.5058 47.3619 20.8176 44.7904C21.7696 47.6215 18.7283 51.6765 13.7708 54.3717L12.0276 48.4993Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M50.6494 56.943H43.9487C42.6135 56.943 42.2797 56.2507 42.5764 55.0886C47.299 54.2232 49.6851 54.6806 50.6494 56.943Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.1333 37.0016L15.897 31.3765C19.2226 32.7364 19.7418 34.8752 17.1333 37.0016Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
export default DigitalNomad;
