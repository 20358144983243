
 export function createAction({ action, headers = {}, type }) {
    return async(dispatch) => {
      dispatch({ type: type.REQUEST, headers });
      try {
        const payload = await action();
        dispatch({ type: type.SUCCESS, headers, payload });
        return payload.data;
      } catch (error) {
        dispatch({ type: type.FAILURE, headers, error });
        console.log('API ERROR:', error);
        return error;
      }
    };
  }

  export function createActionType(id) {
    return {
      FAILURE: `${id}__FAILURE`,
      REQUEST: `${id}__REQUEST`,
      SUCCESS: `${id}__SUCCESS`,
    };
  }
  