const LeisureTravelerIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="84"
      height="60"
      viewBox="0 0 84 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.8333 29.523L49.773 29.4473C49.755 29.4247 49.7398 29.4041 49.7282 29.3881C49.7193 29.3758 49.7101 29.3626 49.7042 29.3543C49.7031 29.3527 49.7021 29.3512 49.7012 29.35C49.6968 29.3438 49.6939 29.3398 49.6922 29.3375C49.243 28.813 48.6889 28.4272 48.0779 28.1577C48.0414 28.1418 48.0021 28.1269 47.9465 28.1076L47.9312 28.1023L47.9162 28.0964C46.3977 27.4951 44.6246 27.7159 43.309 28.8385M49.8333 29.523L63.5257 33.6414C61.2892 35.2797 58.9899 35.9006 56.7977 35.9006C56.3214 35.9006 55.8446 35.8579 55.3512 35.7983L53.769 35.6071L54.6301 36.9481L57.3236 41.1427C57.328 41.1522 57.3358 41.1693 57.344 41.1861C57.3551 41.2091 57.371 41.2404 57.3919 41.2754L57.6722 41.7458L58.2057 41.622C62.1024 40.7175 68.2937 40.2682 75.4414 43.4722L75.4418 43.4724C76.3813 43.8929 76.8023 44.996 76.3816 45.9351C76.0709 46.6268 75.3915 47.0388 74.6776 47.0388C74.4243 47.0388 74.1665 46.9866 73.9175 46.8748L73.917 46.8746C65.5089 43.1086 58.7302 45.1302 56.4623 46.0314L56.3862 46.0616L56.3184 46.1076C56.2854 46.1299 56.2589 46.1509 56.2406 46.166C56.2335 46.1718 56.2266 46.1777 56.2207 46.1827C54.684 47.1498 52.6915 46.8178 51.5433 45.4694L51.5428 45.4688L42.8034 35.2276C41.1788 33.3236 41.405 30.4632 43.309 28.8385M49.8333 29.523L49.9108 29.5809M49.8333 29.523L49.9108 29.5809M43.309 28.8385L42.8222 28.268M43.309 28.8385L42.8222 28.268M42.8222 28.268C44.3623 26.9537 46.4324 26.7022 48.1923 27.3991L42.8222 28.268ZM49.9108 29.5809C50.9826 30.3813 52.6931 31.4524 54.6988 31.9266C56.7292 32.4066 59.0787 32.2749 61.3205 30.6337M49.9108 29.5809L61.3205 30.6337M61.3205 30.6337C62.1545 30.0239 63.3194 30.205 63.9272 31.0346L61.3205 30.6337ZM63.9274 31.0348C64.5358 31.8662 64.3558 33.0327 63.5259 33.6412L63.9274 31.0348Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M48.8032 49.7001L49.0233 49.9181L49.333 49.9173L82.8639 49.8328L82.8444 53.5712L77.9848 53.5555L77.2324 53.5531V54.3055V58.5123H74.8106V54.2882V53.5415L74.0639 53.5382L50.1567 53.4328L48.8992 53.4272L49.4919 54.5363L51.2241 57.7776L51.2244 57.7782C51.402 58.1098 51.3905 58.3715 51.3171 58.5684C51.2371 58.7832 51.0588 58.9829 50.8139 59.113C50.5688 59.2431 50.3017 59.2798 50.0766 59.2261C49.8698 59.1769 49.6472 59.0407 49.4734 58.7142L49.4728 58.7133L46.8606 53.8251L46.6487 53.4286H46.1991H38.6188H37.8688V54.1786V58.5125H35.4469V54.1786V53.4286H34.6969H31.0893V53.2945H45.326H46.5772L45.9875 52.191L43.4036 47.3561L43.3515 47.2586L43.2735 47.1803L31.6292 35.4908L33.1657 34.2034L36.4519 37.4601L37.6413 36.5739L27.5979 17.7806L27.2377 17.1066L26.5706 17.4793C23.6102 19.1333 20.6616 21.308 18.1798 24.1677C18.1801 24.1488 18.1804 24.1298 18.1808 24.1107C18.2046 22.7591 18.275 20.8949 18.4612 18.844C18.8375 14.6977 19.6751 9.94774 21.4481 7.06104L21.6733 6.69445L21.4705 6.31502L20.1305 3.80748L20.1302 3.8069C19.8721 3.32505 20.0542 2.7234 20.5371 2.46431C21.0253 2.20517 21.6247 2.38912 21.8813 2.87089L21.8817 2.87179L23.0084 4.98035L23.1884 5.31705L23.5665 5.36972C25.3703 5.62096 33.2496 6.99764 40.7339 12.5798C40.1496 12.6799 39.4383 12.8151 38.6276 12.9949C36.2874 13.5137 33.1078 14.4056 29.755 15.8994L29.019 16.2273L29.3988 16.938L44.7196 45.6065L44.7729 45.7062L44.8532 45.7857L48.8032 49.7001Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M16.3063 14.0274C15.3556 17.97 14.5856 22.8501 15.3642 26.6156C15.1093 26.6601 14.8022 26.7185 14.4505 26.7937C13.3752 27.0238 11.8794 27.4132 10.1867 28.0518C7.38084 29.1106 4.00422 30.8659 1.11267 33.7431C1.14759 33.5645 1.18568 33.3773 1.2272 33.1822C1.62744 31.301 2.34588 28.6768 3.61676 25.7203C5.97777 20.2278 10.2402 13.5984 17.91 8.44054C17.8924 8.49342 17.8747 8.54716 17.8567 8.60173C17.4045 9.97549 16.823 11.8851 16.3063 14.0274Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M43.6891 11.3907C43.4066 11.1769 43.0403 10.9049 42.6022 10.5905C41.504 9.80251 39.9509 8.74561 38.1299 7.66957C35.3021 5.99858 31.7654 4.24313 28.2558 3.41245C28.4462 3.34956 28.6456 3.28494 28.8536 3.21903C30.9202 2.56409 33.8313 1.78265 37.206 1.2769C43.3894 0.35019 51.0455 0.361063 57.9202 3.68133C56.7004 3.92267 55.0802 4.30788 53.3395 4.89988C49.9567 6.05037 45.9717 8.03103 43.6891 11.3907Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M45.6399 21.6959C45.6399 24.1697 43.6345 26.175 41.1608 26.175C38.687 26.175 36.6816 24.1697 36.6816 21.6959C36.6816 19.2222 38.687 17.2168 41.1608 17.2168C43.6345 17.2168 45.6399 19.2222 45.6399 21.6959Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </svg>
  );
};
export default LeisureTravelerIcon;
