import { LocationDetails, MapDetails, Stage } from "../helpers/actionTypes";

const initState = {
  stage: false,
  data: null
};

export function stage(state = initState, action) {
  switch (action.type) {
    case Stage.STAGE:
      return {
        ...state,
        stage: action.stage,
      };
      case MapDetails.DATA:
      return {
        ...state,
        data: action.data,
      };
      case MapDetails.CLEAR:
      return {};
      case LocationDetails.LOCATION:
      return {
        ...state,
        data: action.data,
      };
      case LocationDetails.CLEAR:
      return {};
    default:
      return state;
  }
}
