const DomesticTravelIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 0C24.0666 0 18.2664 1.75947 13.3329 5.05591C8.39943 8.35235 4.55426 13.0377 2.28363 18.5195C0.0129988 24.0013 -0.581102 30.0333 0.576455 35.8527C1.73401 41.6721 4.59123 47.0176 8.78681 51.2132C12.9824 55.4088 18.3279 58.266 24.1473 59.4235C29.9667 60.5811 35.9987 59.987 41.4805 57.7164C46.9623 55.4457 51.6477 51.6006 54.9441 46.6671C58.2405 41.7336 60 35.9334 60 30C60 22.0435 56.8393 14.4129 51.2132 8.78679C45.5871 3.1607 37.9565 0 30 0ZM30 58C24.4621 58 19.0486 56.3578 14.444 53.2811C9.83947 50.2045 6.25064 45.8315 4.13139 40.7151C2.01213 35.5988 1.45764 29.9689 2.53803 24.5375C3.61841 19.106 6.28515 14.1169 10.201 10.201C14.1169 6.28514 19.106 3.6184 24.5375 2.53801C29.969 1.45763 35.5988 2.01212 40.7151 4.13137C45.8315 6.25063 50.2045 9.83945 53.2812 14.444C56.3578 19.0486 58 24.4621 58 30C58 37.4261 55.05 44.548 49.799 49.799C44.548 55.05 37.4261 58 30 58Z"
        fill="currentColor"
        strokeWidth="1"
      />
      <path
        d="M30 6C25.2533 6 20.6131 7.40758 16.6663 10.0447C12.7195 12.6819 9.6434 16.4302 7.8269 20.8156C6.0104 25.201 5.53512 30.0266 6.46116 34.6822C7.38721 39.3377 9.67299 43.6141 13.0294 46.9706C16.3859 50.327 20.6623 52.6128 25.3178 53.5388C29.9734 54.4649 34.799 53.9896 39.1844 52.1731C43.5698 50.3566 47.3181 47.2805 49.9553 43.3337C52.5924 39.3869 54 34.7467 54 30C54 23.6348 51.4714 17.5303 46.9706 13.0294C42.4697 8.52856 36.3652 6 30 6ZM30 52C24.3344 52.0058 18.885 49.8258 14.7868 45.9138C10.6886 42.0019 8.25753 36.6597 8.00001 31H13C13.2652 31 13.5196 30.8946 13.7071 30.7071C13.8947 30.5196 14 30.2652 14 30C14 29.7348 13.8947 29.4804 13.7071 29.2929C13.5196 29.1054 13.2652 29 13 29H8.00001C8.24441 23.509 10.5354 18.3084 14.4219 14.4219C18.3084 10.5353 23.509 8.2444 29 8V13C29 13.2652 29.1054 13.5196 29.2929 13.7071C29.4804 13.8946 29.7348 14 30 14C30.2652 14 30.5196 13.8946 30.7071 13.7071C30.8947 13.5196 31 13.2652 31 13V8C36.491 8.2444 41.6916 10.5353 45.5781 14.4219C49.4647 18.3084 51.7556 23.509 52 29H47C46.7348 29 46.4804 29.1054 46.2929 29.2929C46.1054 29.4804 46 29.7348 46 30C46 30.2652 46.1054 30.5196 46.2929 30.7071C46.4804 30.8946 46.7348 31 47 31H52C51.7425 36.6597 49.3114 42.0019 45.2132 45.9138C41.115 49.8258 35.6656 52.0058 30 52Z"
        fill="currentColor"
        strokeWidth="1"
      />
      <path
        d="M35.9207 24.6204L35.8607 24.5204L35.7907 24.4104L35.7207 24.3304L35.6207 24.2404L35.5307 24.1804L35.4107 24.1204L18.4107 17.1204C18.2289 17.0477 18.0299 17.0299 17.8381 17.0692C17.6464 17.1086 17.4704 17.2033 17.332 17.3417C17.1935 17.4801 17.0988 17.6561 17.0595 17.8479C17.0201 18.0396 17.0379 18.2387 17.1107 18.4204L24.1107 35.4204L24.1707 35.5204L24.2407 35.6304L24.3107 35.7104L24.4107 35.8004L24.5007 35.8604L24.6207 35.9204L41.6207 42.9204C41.7394 42.9764 41.8695 43.0038 42.0007 43.0004C42.1645 42.9996 42.3255 42.9586 42.4697 42.8809C42.614 42.8033 42.7369 42.6914 42.8277 42.5551C42.9186 42.4189 42.9746 42.2624 42.9908 42.0994C43.007 41.9364 42.9829 41.7719 42.9207 41.6204L35.9207 24.6204ZM19.8407 19.8404L33.2307 25.3504L25.3507 33.2304L19.8407 19.8404ZM26.7707 34.6504L34.6507 26.7704L40.1607 40.1604L26.7707 34.6504Z"
        fill="currentColor"
        strokeWidth="1"
      />
    </svg>
  );
};

export default DomesticTravelIcon;
