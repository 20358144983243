import { useState, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import { useBoolean } from "../../utility/hook";
import NotAvailable from "./NotAvailable";
import ModalComponent from "../../shared/ModalComponent";
import { renderFlag, sortData } from "../../helpers/utility";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import * as onboardService from "../../services/onboard";
import { Storage } from "../../helpers";

const FirstStep = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [selectCountry, setSelectCountry] = useState("");
  const [focus, setFocus] = useState(false);

  // Close Modal
  const closeModal = useCallback(
    (e) => {
      hideModal();
    },
    [hideModal]
  );

  const onSubmit = useCallback(
    (e) => {
      const selectedCountry = props.countries.filter(
        (obj) => obj._id === e.country.value
      )[0];
      Storage.setSelectedCountry(selectedCountry);
      setSelectCountry(selectedCountry);
      if (selectedCountry.isAvailable) {
        sessionStorage.setItem('step' , 2);
        props.nextStep();
      } else {
        showModal();
      }
    },
    [props, showModal]
  );

  const invitePendingCountry = (e) => {
    const data = {
      countryIsoCode: selectCountry.isoCode,
      email: e.email,
      timezone: selectCountry.timezones.zoneName,
    };
    onboardService.pendingCountry(data).then((response) => {
      if (response.status_code === 200) {
      }
    });
  };

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: "flex" }}>
      <div>{renderFlag(label, props.countries)}</div>
    </div>
  );

  return (
    <>
      <ModalComponent
        show={isVisible}
        onHide={(e) => closeModal(e)}
        modalClass="register-modal"
        component={
          <NotAvailable
            close={() => closeModal()}
            invitePendingCountry={(e) => invitePendingCountry(e)}
          />
        }
        type="component"
      />
      <div
        style={{ marginTop: "-93px" }}
        className="d-flex flex-column min-vh-100 justify-content-center align-items-center g-0 shadow-none"
      >
        <h2 className="text-chronoOrange pb-5 mb-4 fw-600 font-san">
          Welcome to Chrono
        </h2>
        <div className="bg-white shadow-none border p-4 br-15 login-form border-onboarding">
          {!focus && (
            <p className="text-center fw-600 fs-20  text-sidebar pb-md-3 px-md-5 font-san pb-sm-1 px-sm-0 ">
              First, let's check to see if Chrono is available in your country.
            </p>
          )}
          {focus && (
            <p className="py-2 text-sidebar fs-20 fw-600 font-san">
              Choose Your Country
            </p>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-4">
              <Controller
                control={control}
                name="country"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={sortData(props.countries, "_id")}
                    styles={REACT_SELECT_BASIC_FIRST_STEP_CUSTOM_STYLE}
                    placeholder="Choose your country"
                    formatOptionLabel={formatOptionLabel}
                    onFocus={() => setFocus(true)}
                  />
                )}
              />
              {errors.country && errors.country.type === "required" && (
                <div className="required text-danger">Country is Required.</div>
              )}
            </Form.Group>
            <div className="mt-5 text-center">
              <Button
                type="submit"
                className="px-5 br-20 text-white fw-400 fw-20 font-san"
              >
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default FirstStep;
export const REACT_SELECT_BASIC_FIRST_STEP_CUSTOM_STYLE = {
  option: (provided, state) => ({
    ...provided,
    padding: "15px 30px",
    color: "#000034",
    textAlign: "left",
    borderBottom: "0.5px solid #D8D8D8",
    zIndex: 5,
    fontSize: "20px",
    fontWeight: 300,
    fontFamily: "Open Sans",
    background: "rgba(237, 237, 237, 0.42)"
  }),

  placeholder: (provided) => ({
    ...provided,
    color: "#9999AE",
    fontSize: "20px",
    fontWeight: 400,
  }),
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    borderBottom: "1px solid #989898",
    outline: 0,
    display: "flex",
    // height: "38px",
    textAlign: "left",
    zIndex: 5,
    padding: "3.5px 0px",
    fontSize: "20px",
    fontWeight: 300,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
  menu: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "15px",
    zIndex: 5,
    background: "rgba(237, 237, 237, 0.75)",
    backdropFilter: "blur(100px)",
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "15px",
    textAlign: "left",
    zIndex: 5,
  }),
};
