import React, { useCallback, useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import { Container } from "react-bootstrap";
import AlertAndLoader from "../../shared/AlertAndLoader";
import * as loaderAction from "../../actions/loader";
import * as onboardService from "../../services/onboard";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FourthStep from "./FourthStep";
import ThirdStep from "./ThirdStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";
import SevenStep from "./SevenStep";
import { useDispatch } from "react-redux";
import RegisterHeader from "./RegisterHeader";

const Register = () => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [emailId, setEmailId] = useState("");
  const [name, setName] = useState("");
  const [countriesTimeZone, setCountriesTimeZone] = useState([]);
  const [mobile, setMobile] = useState("");
  const steps = sessionStorage.getItem('step');

  useEffect(() => {
    if(steps > 3){
        localStorage.setItem('token', sessionStorage.getItem('token'));
      }
  },[steps])

  // Get Available Countries
  const getCountries = useCallback(() => {
    dispatch(loaderAction.loader(true));
    onboardService.availableCountries().then((response) => {
      if (response.status_code === 200) {
        setCountries(response.data);
      }
    });
    dispatch(loaderAction.loader(false));
  }, [dispatch]);

  // Get all countries
  const getAllCountries = useCallback(() => {
    dispatch(loaderAction.loader(true));
    onboardService.allCountries().then((response) => {
      if (response.status_code === 200) {
        setAllCountries(response.data);
      }
    });
    dispatch(loaderAction.loader(false));
  }, [dispatch]);

  // Get all timezone
  const getAlltimezone = useCallback(() => {
    dispatch(loaderAction.loader(true));
    onboardService.allTimezoneWorld().then((response) => {
      if (response.status_code === 200) {
        let arrData = [];
        response.data.forEach((item) => {
          arrData.push({ label: item.name, value: item.key });
        });
        setCountriesTimeZone(arrData);
      }
    });
    dispatch(loaderAction.loader(false));
  }, [dispatch]);

  useEffect(() => {
    getAllCountries();
    getAlltimezone();
    getCountries();

    return () => {
      
      setCountries([]);
      setAllCountries([]);
      setCountriesTimeZone([]);
    };
  }, [getAllCountries, getCountries, getAlltimezone]);

  const onStepChange = (stats) => {};

  return (
    <Container fluid className="register">
      <AlertAndLoader />
      <RegisterHeader />
      <StepWizard initialStep={steps !== null && steps !== undefined ? +steps : 1} onStepChange={onStepChange}>
        <FirstStep
          hashKey={"countries"}
          countries={countries.length > 0 ? countries : []}
        />
        <SecondStep hashKey={"register"} setEmailId={(e) => setEmailId(e)} />
        <ThirdStep
          hashKey={"verification"}
          emailId={emailId !== "" && emailId}
        />
        <FourthStep
          hashKey={"credentials"}
          setName={(e) => setName(e)}
          setMobile={(e) => setMobile(e)}
          countriesTimeZone={countriesTimeZone}
        />
        <FifthStep hashKey={"travel"} name={name !== "" && name} />
        <SixthStep
          hashKey={"goals"}
          countries={allCountries.length > 0 ? allCountries : []}
        />
        <SevenStep hashKey={"accountSetup"} mobile={mobile} />
      </StepWizard>
    </Container>
  );
};
export default Register;
