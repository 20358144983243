import React, { useEffect, useRef, useState, useCallback } from "react";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import Highcharts from "highcharts";
import highchartsMap from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";
import * as statsService from "../../services/stats";
import { useParams } from "react-router-dom";
import { isExpiredWithToken } from "../../helpers/auth.helper";

highchartsMap(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const GlobalTravellerYear = () => {
  const chart = useRef();
  const { token, download } = useParams();
  const [worldData, setWorldData] = useState([]);

  const getStatsData = useCallback((i) => {
    const headers = { Authorization: `Bearer ${i}` };
    statsService.getGlobalTravelWithToken("YEAR", headers).then((response) => {
      if (response.status_code === 200 && response.data.records.length > 0) {
        setWorldData(response.data.records);
      }
    });
  }, []);

  useEffect(() => {
    if (token !== undefined && !isExpiredWithToken(token)) {
      getStatsData(token);
    }
  }, [getStatsData, token]);

  const downlaodChartFile = (type) => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.exportChart({ type: type });
    }
  };

  useEffect(() => {
    if (worldData.length > 0 && download) {
      downlaodChartFile("image/png");
    }
  }, [worldData.length, download]);

  const options = {
    title: {
      text: "",
    },
    chart: {
      spacingLeft: 5,
      spacingRight: 5,
      spacingTop: 0,
      spacingBottom: 0,
      height: "auto",
      type: "map",
      map: worldMap,
      className: "globalChart",
    },
    xAxis: {
      tickPixelInterval: 50,
      crosshair: true,
    },
    yAxis: {
      title: null,
      opposite: true,
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      series: {
        showInLegend: false,
        color: "#22A2FF",
        borderColor: "#22A2FF",
        name: "Visited Places",
        marker: {
          enabled: false,
        },
        threshold: 0,
      },
    },
    mapNavigation: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        showInLegend: false,
        data: worldData,
      },
    ],
    exporting: {
      enabled: false,
    },
  };

  return token !== undefined && !isExpiredWithToken(token) ? (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"mapChart"}
      options={options}
      ref={chart}
    />
  ) : (
    <p>You don't have access this page...</p>
  );
};

export default GlobalTravellerYear;
