const Constants = {
  GENDER_TYPES: {
    MALE: "Male",
    FEMALE: "Female",
  },
  TRAVEL:{
    PROFESSION: {
      BUSINESS_TRAVELER: 'Business Traveler',
      GIG_WORKER: 'Gig Worker',
      HAVE_MULTIPLE_HOMES: 'Have Multiple Homes',
      LEISURE_TRAVELER: 'Leisure Traveler',
      DIGITAL_NOMAD: 'Digital Nomad',
    },
    FREQUENCY: {
      INTERNATIONAL_TRAVELER: 'International Traveler',
      DOMESTIC_TRAVELER: 'Domestic Traveler',
    },
  },
  ERROR_MESSAGES: {
    USER_ALREADY_EXIST: "User already exist!",
    USER_DOES_NOT_EXIST: "User does not exist!",
    EMAIL_ALREADY_TAKEN: "Email is already taken!",
    MOBILE_ALREADY_TAKEN: "mobile already connected to another account!",
    WEAK_PASSWORD: "Password must contain at least one letter and one number",
    USERNAME_ALREADY_TAKEN: "Username is already taken",
    ADMIN_IS_NOT_AVAILABLE: "Admin is not available",
    MISSING_RESOURCES_SECTION:
      "required section is not found in resources of admins!",
    INSUFFICIENT_PERMISSIONS: "Insufficient permissions",
    REQUIRED_VALUES_MISSING: "Required values are missing!",
  },
  PLAN: {
    TYPES: {
      MONTHLY: "Monthly",
      YEARLY: "Yearly",
    },
  },
  GOAL: {
    CATEGORIES: {
      ESTABLISH_RESIDENCY: "Establish Residency",
      VISA_DURATION: "Visa Duration",
      CUSTOM_GOAL: "Custom Goal",
    },
    TYPES: {
      ACHIEVE: "ACHIEVE",
      AVOID: "AVOID",
    },
    STATUS: {
      IN_PROGRESS: 'In progress',
      COMPLETED: 'Completed',
      INCOMPLETE: 'Incomplete',
      DELETED: 'Deleted',
      AT_RISK: 'At risk',
      ARCHIVED: 'Archived',
    },
  },
  SUBSCRIPTION: {
    PLATFORM: {
      ANDROID: "Android",
      IOS: "Ios",
    },
    STATUS: {
      PAID: "Paid",
      CANCELLED: "Cancelled",
    },
  },
  ADMIN_ROLES: {
    SECTIONS: {
      TEST: "test", // update sections list
    },
    PERMISSIONS: {
      CREATE: "CREATE",
      READ: "READ",
      UPDATE: "UPDATE",
      DELETE: "DELETE",
      LIST: "LIST",
    },
  },
  VERIFICATION_TYPES: {
    EMAIL: "EMAIL",
    MOBILE: "MOBILE",
  },
  USER_ADDRESS_TYPES: {
    PRIMARY: "PRIMARY",
    SECONDARY: "SECONDARY",
  },
  LOCATION: {
    TYPES: {
      COUNTRY: "COUNTRY",
      STATE: "STATE",
      CITY: "CITY",
    },
    TRACKING: {
      TYPES: {
        MANUAL: "MANUAL",
        PASSIVE: "PASSIVE",
      },
    },
  },
  FREQUENCY: {
    YEARLY: 'YEARLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
  },
  VISA_TYPES: {
    WORK: 'Work',
    TOURIST: 'Tourist',
    STUDENT: 'Student',
    INVESTOR: 'Investor',
  }
};

export default Constants;

export const locationType = [
  {label : 'Country' , value : Constants.LOCATION.TYPES.COUNTRY},
  {label : 'State/Region' , value : Constants.LOCATION.TYPES.STATE},
  {label : 'City' , value : Constants.LOCATION.TYPES.CITY},
]

export const quarter = [
  { label: "Jan-Mar", value: "1" },
  { label: "Apr-Jun", value: "2" },
  { label: "Jul-Sep", value: "3" },
  { label: "Oct-Dec", value: "4" },
]