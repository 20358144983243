import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { AlertComponent } from "./AlertComponent";
import Loader from "./Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as alertActions from "../actions/alert";

const AlertAndLoader = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const { loading } = useSelector((state) => state.loader);

  useEffect(() => {
    if (alert !== undefined && alert !== null) {
      if (alert.type === "success") toast.success(alert.message);
      if (alert.type === "danger") toast.error(alert.message);
      if (alert.type === "info") toast.info(alert.message);
      if (alert.type === "warning") toast.warning(alert.message);
      if (alert.message) dispatch(alertActions.clear());
    }
  }, [alert, dispatch]);

  return (
    <>
      <Loader loading={loading} />
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{ position: "relative" }}
      >
        <div
         className="alert-tostify"
        >
          {/* <AlertComponent alert={alert} /> */}
          <ToastContainer
            position="bottom-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </>
  );
};
export default AlertAndLoader;
