import { GOALS } from "../helpers/actionTypes";

const initState = {
  goal:null
};

export function goal(state = initState, action) {
  switch (action.type) {
    case GOALS.ADD:
      return {
        ...state,
        goal: action.data,
      };
      case GOALS.CLEAR:
      return {};
    default:
      return state;
  }
}