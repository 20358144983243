import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoImg from "../../assets/img/bigLogo.png";
import ArrowStart from "../../assets/img/arrow-start.png"

const GetStarted = () => {
  return (
    <Container
      fluid
      className="register d-flex align-items-center min-vh-100 justify-content-center flex-column"
    >
      <h2 className="text-white m-0 mb-3 fw-400 ">
        Welcome to 
      </h2>
      <Image className="get-Started-logo" src={LogoImg} style={{'maxWidth': "420px" ,'maxHeight': "144px" }} />
       <Link to="/register" className="text-decoration-none text-white m-0 mb-2 p-0 mt-5 fs-26 fw-500">Let's get started <Image src={ArrowStart} style={{'width' : '16px' , 'height' : '14px'}} /></Link>
    </Container>
  );
};
export default GetStarted;
