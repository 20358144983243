import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = "onboard";

export async function availableCountries() {
  return await handleApi(axiosClient.get(`${BASE}/available-countries`));
}

export async function allCities(countryCode = "", state = "") {
  return await handleApi(
    axiosClient.get(
      `${BASE}/all-cities${
        countryCode && "?countryCode=" + countryCode + "&stateCode=" + state
      }`
    )
  );
}

export async function allStates(countryCode = "") {
  return await handleApi(
    axiosClient.get(
      `${BASE}/all-states${countryCode && "?countryCode=" + countryCode}`
    )
  );
}

export async function allCountries() {
  return await handleApi(axiosClient.get(`${BASE}/all-countries`));
}

export async function allTimezoneWorld() {
  return await handleApi(axiosClient.get(`${BASE}/timezones`));
}

export async function signup(data) {
  return await handleApi(axiosClient.post(`${BASE}/signup`, data));
}

export async function verify(data) {
  return await handleApi(axiosClient.post(`${BASE}/verify`, data));
}

export async function inviteUser(data) {
  return await handleApi(axiosClient.post(`${BASE}/invite`, data));
}

export async function resendEmail(data) {
  return await handleApi(
    axiosClient.post(`${BASE}/resend-email-verification`, data)
  );
}

export async function pendingCountry(data) {
  return await handleApi(axiosClient.post(`${BASE}/pending-country`, data));
}
