import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { rootReducer } from "./rootReducer";
import { history } from "./history";

const loggerMiddleware = createLogger();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === "development") {
  middlewares.push(loggerMiddleware);
}

/* The Redux Enhancements */
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const reduxStore = createStore(rootReducer(history), enhancer);
export default reduxStore;
