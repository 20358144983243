import { Button, Col, Form, Row } from "react-bootstrap";
import BusinessTravellerIcon from "./../../assets/icons/BusinessTravellerIcon";
import GigWorkerIcon from "./../../assets/icons/GigWorkerIcon";
import MultipleHomesIcon from "./../../assets/icons/MultipleHomes";
import LeisureTravelerIcon from "./../../assets/icons/LeisureTravelerIcon";
import InternationTravelIcon from "./../../assets/icons/InternationTravelIcon";
import DomesticTravelIcon from "./../../assets/icons/DomesticTravelIcon";
import Constants from "../../utility/Constants";
import { useForm } from "react-hook-form";
import * as userServices from "../../services/user";
import * as loaderActions from "../../actions/loader";
import * as alertActions from "../../actions/alert";
import { useDispatch } from "react-redux";
import DigitalNomad from "../../assets/icons/DigitalNomad";
import RightArrow from "../../assets/icons/RightArrow";
import LeftArrow from "./../../assets/icons/LeftArrow";

const FifthStep = (props) => {
  const dispatch = useDispatch();
  const userData = JSON.parse(sessionStorage.getItem('user'));
  const { register, handleSubmit } = useForm({
    defaultValues : {
      'Business Travel' : userData !== null ?  userData.profession.includes(Constants.TRAVEL.PROFESSION.BUSINESS_TRAVELER) ? true : false : false,
      'Digital Nomad' :  userData !== null ? userData.profession.includes(Constants.TRAVEL.PROFESSION.DIGITAL_NOMAD) ? true : false : false,
      'Gig Worker' :  userData !== null  ? userData.profession.includes(Constants.TRAVEL.PROFESSION.GIG_WORKER) ? true : false : false,
      'Homes' :  userData !== null ? userData.profession.includes(Constants.TRAVEL.PROFESSION.HAVE_MULTIPLE_HOMES) ? true : false : false,
      'Leisure Traveler' :  userData !== null ? userData.profession.includes(Constants.TRAVEL.PROFESSION.LEISURE_TRAVELER) ? true : false : false,
      'International Travel' :  userData !== null ? userData.travelFrequency.includes(Constants.TRAVEL.FREQUENCY.INTERNATIONAL_TRAVELER) ? true : false : false,
      'Domestic Travel' :  userData !== null ? userData.travelFrequency.includes(Constants.TRAVEL.FREQUENCY.DOMESTIC_TRAVELER) ? true : false : false
    }
  });

  // Card Data Array
  const cardData = [
    [
      {
        title: "Why you travel",
        label: "Business Traveler",
        id: "radio1",
        type: "checkbox",
        name: Constants.TRAVEL.PROFESSION.BUSINESS_TRAVELER,
        icon: <BusinessTravellerIcon />,
      },

      {
        label: "Digital Nomad",
        id: "radio7",
        type: "checkbox",
        name: Constants.TRAVEL.PROFESSION.DIGITAL_NOMAD,
        icon: <DigitalNomad />,
      },
      {
        label: "Gig Worker",
        id: "radio2",
        type: "checkbox",
        name: Constants.TRAVEL.PROFESSION.GIG_WORKER,
        icon: <GigWorkerIcon />,
      },
      {
        label: "Owner of Multiple Homes",
        id: "radio3",
        type: "checkbox",
        name: Constants.TRAVEL.PROFESSION.HAVE_MULTIPLE_HOMES,
        icon: <MultipleHomesIcon />,
        width: true,
      },
      {
        label: "Leisure Traveler",
        id: "radio4",
        type: "checkbox",
        name: Constants.TRAVEL.PROFESSION.LEISURE_TRAVELER,
        icon: <LeisureTravelerIcon />,
      },
    ],
    [
      {
        title: "Where you travel",
        label: "International Traveler",
        id: "radio5",
        type: "checkbox",
        name: Constants.TRAVEL.FREQUENCY.INTERNATIONAL_TRAVELER,
        icon: <InternationTravelIcon />,
      },
      {
        label: "Domestic Traveler",
        id: "radio6",
        type: "checkbox",
        name: Constants.TRAVEL.FREQUENCY.DOMESTIC_TRAVELER,
        icon: <DomesticTravelIcon />,
      },
    ],
  ];

  const onSubmit = (e) => {
    let travelFrequency = [],
      profession = [];
    Object.keys(e).map((obj) =>
      e[obj]
        ? obj === Constants.TRAVEL.FREQUENCY.INTERNATIONAL_TRAVELER ||
          obj === Constants.TRAVEL.FREQUENCY.DOMESTIC_TRAVELER
          ? travelFrequency.push(obj)
          : profession.push(obj)
        : ""
    );
    if (travelFrequency.length > 0 || profession.length > 0) {
      dispatch(loaderActions.loader(true));
      const data = {};
      if (travelFrequency.length > 0) data.travelFrequency = travelFrequency;
      if (profession.length > 0) data.profession = profession;
      console.log(data);
      userServices.accountUpdate(data).then((response) => {
        if (response.status_code === 200) {
          sessionStorage.setItem('user',  JSON.stringify(response.data));
          dispatch(alertActions.success("Your travel info. has been saved."));
          setTimeout(() => {
            sessionStorage.setItem('step' , 6);
            props.nextStep();
          }, 1000);
        } else if (response.status_code !== 200) {
          sessionStorage.setItem('step' , 5);
          dispatch(alertActions.error(response.error));
        }
        dispatch(loaderActions.loader(false));
      });
    } else {
      props.nextStep();
    }
  };

  return (
    <Row
      style={{ marginTop: "-93px" }}
      className="d-flex flex-column min-vh-100 justify-content-center align-items-center g-0 font-san"
    >
      <Col md="12" sm="12" lg="12" className="text-center">
        <h2 className="text-chronoOrange pt-4 fw-600 pb-4">Hi {props.name}!</h2>
        <p className="w-md-25 w-lg-25 w-100 mx-auto fs-18 fw-400 pb-1 text-white">
          Tell us a little about yourself.
          <br /> Select all that apply below:
        </p>
      </Col>
      <Col md="12" sm="12" lg="12">
        <Row>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {cardData.map((item, index) => (
              <Col md="12" sm="12" key={"cardSecond-" + index}>
                <div className="p-1 custom-radio w-100">
                  <p
                    className={"text-center text-white fw-400 fs-20 pb-1 pt-3"}
                    style={{ marginTop: !item[0].title ? "40px" : 0 }}
                  >
                    {item[0].title && item[0].title}
                  </p>

                  <div className="d-flex justify-content-center flex-md-row align-content-center flex-wrap flex-lg-row flex-column ">
                    {item.map((obj, i) => (
                      <div
                        key={"labelStart-" + i}
                        style={{ width: "180px", minHeight: "120px" }}
                        className={
                          index === 0
                            ? "px-3 py-2 mb-3 bg-white  border-radio border-0  d-flex justify-content-around br-20 text-center shadow-none  mx-3"
                            : " px-3 py-2 mb-3 bg-white  border-radio border-0  d-flex justify-content-around br-20 text-center shadow-none  mx-2"
                        }
                      >
                        <label htmlFor={obj.id}>
                          <input
                            type={obj.type}
                            id={obj.id}
                            name={obj.name}
                            {...register(obj.name)}
                          />
                          <div className="image">
                            {obj.icon}
                            <p
                              className="fs-16 fw-300 font-san mt-2 pb-0 mb-0 "
                              style={
                                obj.width !== undefined
                                  ? { width: "120px" }
                                  : { whiteSpace: "noWrap" }
                              }
                            >
                              {obj.label}
                            </p>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            ))}
            <div className="d-flex justify-content-between pt-4 mt-4 login-form mx-auto px-5 mb-2">
              <Button
                className="btn-clear shadow-none bg-none "
                onClick={() => { 
                  props.previousStep()
                  sessionStorage.setItem('step', 4);
                }}
              >
                <LeftArrow className="arrow-svg-hover" />
              </Button>
              <Button type="submit" className="btn-clear shadow-none bg-none ">
                <RightArrow className="arrow-svg-hover" />
              </Button>
            </div>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};
export default FifthStep;
