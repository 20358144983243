import { Col, Image, Row } from "react-bootstrap";
import logoImg from "../../assets/img/whiteLogo.svg";
import ModalComponent from "../../shared/ModalComponent";
import { useBoolean } from "../../utility/hook";
import * as userServices from "../../services/user";

const RegisterHeader = (props) => {
  const [isVisible, showModal, hideModal] = useBoolean(false);

  // const handler = (e) => {
  //   // window.onbeforeunload = null;
  //   e.preventDefault();
  //   // e.returnValue = " ";
  //   sessionStorage.setItem("onboardingStep", true);
  //   showModal();
  //   return true;
  // };

  const hide = () => {
    if (
      sessionStorage.getItem("step") !== null &&
      +sessionStorage.getItem("step") !== 2
    ) {
      userServices.userDelete().then((res) => {
        hideModal();
        window.location.href = 'https://www.gochrono.io';
      });
    } else {
      hideModal();
      window.location.href = 'https://www.gochrono.io';
    }
  };

  return (
    <>
      <ModalComponent
        title={"Exit setup"}
        show={isVisible}
        onHide={(e) => hideModal(e)}
        component={<MessageBox hide={() => hide()} />}
        type="component"
      />
      <Row>
        <Col
          md="12"
          sm="12"
          className="bg-sidebar px-md-5  py-4 text-white d-flex justify-content-between align-items-center"
        >
          <Image
            style={{ zIndex: 9999 }}
            src={logoImg}
            className="img-fluid pointer"
            onClick={() => showModal()}
          />
          <a
            style={{ zIndex: 9999 }}
            className="text-decoration-none text-white pointer"
            href="https://www.gochrono.io/chrono-faq"
            target="_blank"
            rel="noreferrer"
          >
            Need Help?
          </a>
        </Col>
      </Row>
    </>
  );
};
export default RegisterHeader;

const MessageBox = ({ hide }) => {
  return (
    <>
      <div className="text-center">
        <p className="my-3 fs-20">
          Are you sure you want to exit Chrono setup?{" "}
        </p>
        <button
          onClick={() => hide()}
          className="btn btn-primary text-decoration-none text-white px-5"
        >
          Exit
        </button>
      </div>
    </>
  );
};
