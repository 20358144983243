import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState, useCallback } from "react";
import highchartsMap from "highcharts/modules/map";
import * as statsServices from "../../services/stats";
import { useParams } from "react-router-dom";
import { isExpiredWithToken } from "../../helpers/auth.helper";
import usaCities from "@highcharts/map-collection/countries/us/custom/us-all-territories.geo.json";
import proj4 from "proj4";
highchartsMap(Highcharts);

const AmericanCity = () => {
  const { token } = useParams();
  const [usaData, setUsaData] = useState([]);

  const getDataFromAPI = useCallback((i) => {
    const headers = { Authorization: `Bearer ${i}` };
    statsServices
      .getVisitedUSAPlacesWithHeader("CITY", headers)
      .then((response) => {
        if (response.status_code === 200 && response.data.records.length > 0) {
          setUsaData(response.data.records);
        }
      });
  }, []);

  useEffect(() => {
    if (token !== undefined && !isExpiredWithToken(token)) {
      getDataFromAPI(token);
    }
  }, [getDataFromAPI, token]);

  return token !== undefined && !isExpiredWithToken(token) ? (
    <City data={usaData} />
  ) : (
    <p>You don't have access this page...</p>
  );
};

export default AmericanCity;

const City = ({ data }) => {
  const options2 = {
    title: {
      text: "",
    },
    chart: {
      spacingLeft: 5,
      spacingRight: 5,
      spacingTop: 5,
      spacingBottom: 5,
      height: 350,
      type: "map",
      map: usaCities,
      proj4,
      className: "globalChart",
    },
    xAxis: {
      tickPixelInterval: 50,
    },
    yAxis: {
      title: null,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        showInLegend: false,
        color: "#8FD0FF",
        label: {
          enabled: false,
        },
        name: "Visited Places",
        marker: {
          enabled: false,
        },
        threshold: 0,
      },
    },
    mapNavigation: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Basemap",
        mapData: usaCities,
        borderColor: "#606060",
        nullColor: "rgba(200, 200, 200, 0.2)",
        showInLegend: false,
      },
      {
        type: "mappoint",
        name: "Cities",
        color: "red",
        data,
      },
    ],
    exporting: {
      enabled: false,
    },
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"mapChart"}
      options={options2}
    />
  );
};
