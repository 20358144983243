import { ConnectedRouter } from "connected-react-router";
import { history } from "../../helpers";
import { Switch, Route, Redirect } from "react-router-dom";
import { PublicRoute } from "./PublicRoute";
// import Login from "../login/Login";
// import PasswordForgot from "../login/PasswordForgot";
import Register from "../register/Register";
// import EmailForgot from "../login/EmailForgot";
import GlobalTravellerAllTime from "./GlobalTravellerAllTime";
import GlobalTravellerYear from "./GlobalTravellerYear";
import AmericanState from "./AmericanState";
import AmericanCity from "./AmericanCity";
import GetStarted from "./GetStarted";
import PageNotFound from "../layout/PageNotFound";

function App() {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/getStarted" />
        </Route>

        {/* Public Routes for Login , register  & Password Forgot */}

        <PublicRoute component={GetStarted} restricted={true} path="/getStarted" exact />
        {/* <PublicRoute component={Login} restricted={true} path="/login" exact /> */}
        {/* Global Traveler All Time for Android and iOS */}
        <PublicRoute
          component={GlobalTravellerAllTime}
          restricted={true}
          path="/global-traveller-all/:token"
          exact
        />
        {/* Global Traveler Year for Android and iOS */}
        <PublicRoute
          component={GlobalTravellerYear}
          restricted={true}
          path="/global-traveller-year/:token"
          exact
        />
        {/* Global Traveler All Time for Android and iOS */}
        <PublicRoute
          component={GlobalTravellerAllTime}
          restricted={true}
          path="/global-traveller-all/:token/:download"
          exact
        />
        {/* Global Traveler Year for Android and iOS */}
        <PublicRoute
          component={GlobalTravellerYear}
          restricted={true}
          path="/global-traveller-year/:token/:download"
          exact
        />
        {/* American City for Android and iOS */}
        <PublicRoute
          component={AmericanCity}
          restricted={true}
          path="/american-city/:token"
          exact
        />
        {/* American State for Android and iOS */}
        {/* <PublicRoute
          component={AmericanState}
          restricted={true}
          path="/american-region/:token"
          exact
        /> */}
        <PublicRoute
          component={AmericanState}
          restricted={true}
          path="/american-region/:token"
          exact
        />

        {/* <PublicRoute
          component={PasswordForgot}
          restricted={true}
          path="/passwordForgot"
          exact
        />
        <PublicRoute
          component={EmailForgot}
          restricted={true}
          path="/emailForgot"
          exact
        /> */}
        <PublicRoute
          component={Register}
          restricted={true}
          path="/register"
          exact
        />

          <PublicRoute
          component={PageNotFound}
          path="*"
          exact
        />

        {/* MainLayout Route for Register Users */}
        {/* {Routes.map((item, index) => (
          <MainLayout
            key={index}
            path={item.path}
            component={item.component}
            exact={item.exact}
          />
        ))} */}
      </Switch>
    </ConnectedRouter>
  );
}

export default App;
