import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = "account";

export async function accountUpdate(data) {
  return await handleApi(axiosClient.patch(`${BASE}`, data));
}

export async function userDelete() {
  return await handleApi(axiosClient.get(`${BASE}/delete`));
}

export async function accountDelete() {
  return await handleApi(axiosClient.delete(`/profile`));
}

export async function verifyPassword(data) {
  return await handleApi(axiosClient.post(`${BASE}/verify-password`, data));
}

