import { useEffect, useState, useCallback } from 'react';
import { Col, Row, ButtonGroup, Form, Button, ToggleButton, Badge, InputGroup } from 'react-bootstrap';
import { useBoolean } from '../../utility/hook';
import ModalComponent from '../../shared/ModalComponent';
import * as goalsServices from '../../services/goals';
import Constants, { locationType } from '../../utility/Constants';
import { getSortData, renderFlag, sortData } from '../../helpers/utility';
import * as alertActions from '../../actions/alert';
import * as loaderActions from '../../actions/loader';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import ResidencyIcon from '../../assets/icons/ResidencyIcon';
import VisaIcon from '../../assets/icons/VisaIcon';
import CustomGoalIcon from '../../assets/icons/CustomGoalIcon';
import LoveDataIcon from '../../assets/icons/LoveDataIcon';
import * as onboardService from '../../services/onboard';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Storage } from '../../helpers';
import LoaderInline from './../../shared/LoaderInline';
import ConfirmModal from '../../shared/ConfirmModal';
import moment from 'moment';
import { AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai';
import RightArrow from '../../assets/icons/RightArrow';
import LeftArrow from '../../assets/icons/LeftArrow';

const SixthStep = (props) => {
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState('');
  const [isVisible, showModal, hideModal] = useBoolean(false);
  const [countries, setCountries] = useState([]);
  const [residencyGoal, setResidencyGoal] = useState(
    sessionStorage.getItem('residencyGoal') !== undefined && sessionStorage.getItem('residencyGoal') !== null
      ? sessionStorage.getItem('residencyGoal')
      : 0
  );
  const [visaGoal, setVisaGoal] = useState(
    sessionStorage.getItem('visaGoal') !== undefined && sessionStorage.getItem('visaGoal') !== null
      ? sessionStorage.getItem('visaGoal')
      : 0
  );
  const [customGoal, setCustomGoal] = useState(
    sessionStorage.getItem('customGoal') !== undefined && sessionStorage.getItem('customGoal') !== null
      ? sessionStorage.getItem('customGoal')
      : 0
  );
  const [haveData, setHaveData] = useState(false);
  const [isVisibleConfirm, showConfirm, hideConfirm] = useBoolean(false);

  useEffect(() => {
    if (props.countries.length > 0) {
      setCountries(props.countries);
    }
    return () => setCountries([]);
  }, [props]);

  // Open Modal for Select Option in Card
  const openModal = useCallback(() => {
    showModal();
  }, [showModal]);

  // Click on Select the card and open modal
  const onChange = useCallback(
    (e) => {
      setActiveCard(e);
      if (e.component !== undefined) {
        openModal();
      }
    },
    [openModal]
  );

  // Create Goals
  const addGoal = useCallback(
    (e) => {
      dispatch(loaderActions.loader(true));
      const data = {
        category: e.category,
        city: e.selectedCity,
        country: e.selectedCountry,
        state: e.selectedState,
        daysCount: +e.days,
        countryName: e.countryName,
        stateName: e.stateName,
        fromDate: e.fromDate,
        locationType:
          e.location === Constants.LOCATION.TYPES.COUNTRY
            ? Constants.LOCATION.TYPES.COUNTRY
            : e.location === Constants.LOCATION.TYPES.STATE
            ? Constants.LOCATION.TYPES.STATE
            : Constants.LOCATION.TYPES.CITY,
        toDate: e.toDate,
        type: e.goalType,
      };
      if (e.category === Constants.GOAL.CATEGORIES.VISA_DURATION) {
        data.visaType = e.visaType;
      }
      goalsServices.createGoal(data).then((response) => {
        if (response.status_code === 200) {
          dispatch(alertActions.success('Your goal created Successfully.'));
          if (e.category === Constants.GOAL.CATEGORIES.ESTABLISH_RESIDENCY) {
            setResidencyGoal(1 + residencyGoal);
            sessionStorage.setItem('residencyGoal', 1 + residencyGoal);
          } else if (e.category === Constants.GOAL.CATEGORIES.VISA_DURATION) {
            setVisaGoal(1 + visaGoal);
            sessionStorage.setItem('visaGoal', 1 + visaGoal);
          } else if (e.category === Constants.GOAL.CATEGORIES.CUSTOM_GOAL) {
            setCustomGoal(1 + customGoal);
            sessionStorage.setItem('customGoal', 1 + customGoal);
          }
        } else if (response.status_code !== 200) {
          dispatch(alertActions.error('Please try after sometime.'));
        }
        dispatch(loaderActions.loader(false));
        //hideModal();
      });
    },
    [dispatch, residencyGoal, visaGoal, customGoal]
  );

  // Card Data Array
  const cardData = [
    {
      label: 'Tax Residency',
      id: 'radio1',
      name: 'card',
      number: residencyGoal,
      icon: <ResidencyIcon size={true} />,
      className: 'd-flex justify-content-end my-3 mt-5 p-0',
      component: (
        <Residency
          title={'Tax Residency'}
          countries={countries}
          getData={(e) => addGoal(e)}
          category={Constants.GOAL.CATEGORIES.ESTABLISH_RESIDENCY}
          setHaveData={setHaveData}
          hide={hideModal}
        />
      ),
    },
    {
      label: 'Visa Duration',
      id: 'radio2',
      name: 'card',
      number: visaGoal,
      className: 'd-flex justify-content-center my-3 mt-5 p-0',
      icon: <VisaIcon size={true} />,
      component: (
        <Residency
          title={'Visa Duration'}
          countries={countries}
          getData={(e) => addGoal(e)}
          category={Constants.GOAL.CATEGORIES.VISA_DURATION}
          setHaveData={setHaveData}
          hide={hideModal}
        />
      ),
    },
    {
      label: 'Custom Goal',
      id: 'radio4',
      name: 'card',
      number: customGoal,
      icon: <CustomGoalIcon size={true} />,
      className: 'd-flex justify-content-start my-3 mt-5 p-0',
      component: (
        <Residency
          title={'Custom Goal'}
          countries={countries}
          getData={(e) => addGoal(e)}
          category={Constants.GOAL.CATEGORIES.CUSTOM_GOAL}
          setHaveData={setHaveData}
          hide={hideModal}
        />
      ),
    },
    {
      label: 'I Just Love Data',
      id: 'radio3',
      className: 'd-flex justify-content-center my-3 mt-5 p-0',
      name: 'card',
      icon: <LoveDataIcon size={true} />,
    },
  ];

  const onClose = () => {
    if (haveData) {
      showConfirm();
    } else {
      hideModal();
      setActiveCard('');
      setHaveData(false);
    }
  };

  return (
    <>
      <ConfirmModal
        title={'Do you want to save this goal data ?'}
        deleteEnable={false}
        show={isVisibleConfirm}
        acceptConfirm={(e) => hideConfirm()}
        onHide={() => {
          hideConfirm();
          hideModal();
          setHaveData(false);
        }}
      />
      <ModalComponent
        modalClass={'goals-modal'}
        show={isVisible}
        onHide={(e) => onClose(e)}
        component={activeCard.component}
        type="component"
        button={'Save goal'}
      />
      <Row
        style={{ marginTop: '-95px' }}
        className="d-flex flex-column min-vh-100 justify-content-center align-items-center g-0 font-san "
      >
        <Col md="12" className="text-center mt-5 pt-5">
          <h2 className="text-chronoOrange fw-600 pt-5 mt-5">Your Goals</h2>
          <p className="fs-18 fw-400 text-white mt-4">What can Chrono help you keep track of?</p>
        </Col>
        <Col md="12" sm="12" className="pt-5 mt-5">
          <Row className="w-75 mx-auto d-flex justify-content-center">
            {cardData.map((item, index) => (
              <div key={'card-' + index} className="custom-radio position-relative mb-3" style={{ width: '185px' }}>
                {item.number !== undefined && item.number ? (
                  <>
                    <Badge
                      className="text-white position-absolute top-10 font-san fs-16 fw-600 rounded-circle d-flex justify-content-center align-content-center"
                      style={{
                        right: '5px',
                        top: '-5px',
                        width: '30px',
                        height: '30px',
                      }}
                      bg="chronoOrange"
                    >
                      {item.number > 1 ? '+1' : item.number}
                    </Badge>
                  </>
                ) : null}
                <div className="border-radio br-20 pt-4 pb-1 bg-white px-3 text-center shadow-none w-100">
                  <label onClick={(e) => onChange(item)} className="w-100" htmlFor={item.id}>
                    <input
                      type="radio"
                      onChange={(e) => onChange('radio1')}
                      checked={item.id === activeCard.id}
                      id={item.id}
                      name={item.name}
                    />
                    <div className="image d-flex flex-column justify-content-center align-items-center">
                      {item.icon}
                      <p
                        style={item.number > 0 ? { color: '#22A2FF' } : {}}
                        className="line-height-30 p-0 m-0 pt-3 fs-16 fw-400 font-san"
                      >
                        {item.label}
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </Row>
        </Col>
        <Col className="d-flex justify-content-between login-form align-items-center mb-2">
          <Button
            className="btn-clear shadow-none bg-none"
            onClick={() => {
              props.previousStep();
              sessionStorage.setItem('step', 5);
            }}
          >
            <LeftArrow className="arrow-svg-hover" />
          </Button>
          <Button
            className="btn-clear text-white shadow-none bg-none fw-600 fs-16 font-theme fs-normal"
            onClick={() => {
              props.nextStep();
              sessionStorage.setItem('step', 7);
            }}
          >
            Do this later
          </Button>
          <Button
            className="btn-clear shadow-none"
            onClick={() => {
              props.nextStep();
              sessionStorage.setItem('step', 7);
            }}
          >
            <RightArrow className="arrow-svg-hover" />
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default SixthStep;

const Residency = ({ title, countries, getData, category, setHaveData, hide }) => {
  const dispatch = useDispatch();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country: '',
      state: '',
      city: '',
      date: new Date(),
    },
  });
  const [toggleOption, setToggleOption] = useState(Constants.LOCATION.TYPES.COUNTRY);
  const [type, setType] = useState(Constants.GOAL.TYPES.ACHIEVE);
  const [visaType, setVisaType] = useState(Constants.VISA_TYPES.WORK);
  const [selectedCountries, setSelectedCountries] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [infoVisible, setInfoVisible] = useState(false);
  const [thresholdValue, setThresholdValue] = useState('');
  const [valueError, setValueError] = useState(false);
  const dateFormat =
    Storage.getSession() && Storage.getSession().dateFormat === 'DD/MM/YYYY' ? `dd/MM/yyyy` : `MM/dd/yyyy`;

  //Get All States
  const getStates = useCallback(
    (countryCode) => {
      setLoading(true);
      onboardService.allStates(countryCode).then((response) => {
        if (response.status_code === 200) {
          setStates(response.data);
          if (response.data.length === 0) {
            dispatch(alertActions.info('There are no states'));
          }
        }
        setLoading(false);
      });
    },
    [dispatch]
  );

  // USE Effect use to watchh data if user enter something
  useEffect(() => {
    const subscription = watch((value) => {
      Object.keys(value).forEach((item) => {
        if (value[item] !== undefined) {
          setHaveData(true);
        }
      });
    });
    return () => subscription.unsubscribe();
  }, [watch, setHaveData]);

  // Get All Cities
  const getCities = useCallback(
    (countryCode, stateCode) => {
      setLoading(true);
      onboardService.allCities(countryCode, stateCode).then((response) => {
        if (response.status_code === 200) {
          setCities(response.data);
          if (response.data.length === 0) {
            dispatch(alertActions.info('There are no cities'));
          }
        }
        setLoading(false);
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedCountries && selectedCountries.value) {
      setSelectedState('');
      setSelectedCity('');
    }
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedCountries && selectedCountries.value) {
      const data = getSortData(countries, 'name', selectedCountries.value);
      goalsServices.getThreshold('COUNTRY', data.isoCode).then((response) => {
        if (response.status_code === 200) {
          setThresholdValue(response.data.threshold.threshold !== undefined ? response.data.threshold.threshold : '');
          if (Constants.GOAL.CATEGORIES.ESTABLISH_RESIDENCY === category) {
            setStartDate(new Date(response.data.threshold.financialStartDate.replaceAll('-', '/')));
            setEndDate(new Date(response.data.threshold.financialEndDate.replaceAll('-', '/')));
          } else if (Constants.GOAL.CATEGORIES.VISA_DURATION === category) {
            setStartDate(new Date(response.data.threshold.financialStartDate.replaceAll('-', '/')));
            setEndDate('');
          } else if (Constants.GOAL.CATEGORIES.CUSTOM_GOAL === category) {
            setStartDate(new Date());
            setEndDate('');
          }
        }
      });
    }
  }, [category, countries, selectedCountries, selectedCountries.value]);

  // Get States
  useEffect(() => {
    if (selectedCountries && selectedCountries.value && toggleOption === Constants.LOCATION.TYPES.STATE) {
      getStates(getSortData(countries, 'name', selectedCountries.value).isoCode);
    }
  }, [countries, getStates, selectedCountries, toggleOption]);

  //Get Cities
  useEffect(() => {
    if (selectedState && selectedState.value && toggleOption === Constants.LOCATION.TYPES.CITY) {
      getCities(
        getSortData(states, 'name', selectedState.value).countryCode,
        getSortData(states, 'name', selectedState.value).isoCode
      );
    }
  }, [countries, getCities, toggleOption, selectedState, states]);

  //Get countries
  useEffect(() => {
    if (selectedCountries && selectedCountries.value && toggleOption === Constants.LOCATION.TYPES.CITY) {
      getStates(getSortData(countries, 'name', selectedCountries.value).isoCode);
    }
  }, [countries, getStates, toggleOption, selectedCountries]);

  const onSubmit = (e) => {
    if (thresholdValue !== '') {
      const data = {
        category: category,
        countryName: selectedCountries.label,
        stateName: selectedState.label,
        selectedCountry:
          toggleOption === Constants.LOCATION.TYPES.COUNTRY
            ? getSortData(countries, 'name', selectedCountries.value).isoCode
            : toggleOption === Constants.LOCATION.TYPES.STATE
            ? getSortData(states, 'name', selectedState.value).countryCode
            : toggleOption === Constants.LOCATION.TYPES.CITY
            ? getSortData(cities, 'name', selectedCity.value).countryCode
            : '',
        selectedState:
          toggleOption === Constants.LOCATION.TYPES.STATE
            ? getSortData(states, 'name', selectedState.value).isoCode
            : toggleOption === Constants.LOCATION.TYPES.CITY
            ? getSortData(cities, 'name', selectedCity.value).stateCode
            : null,
        selectedCity: selectedCity && selectedCity.value,
        location:
          toggleOption === Constants.LOCATION.TYPES.COUNTRY
            ? Constants.LOCATION.TYPES.COUNTRY
            : toggleOption === Constants.LOCATION.TYPES.STATE
            ? Constants.LOCATION.TYPES.STATE
            : Constants.LOCATION.TYPES.CITY,
        days: +thresholdValue,
        goalType: type,
        fromDate: new Date(startDate).toISOString(),
        toDate: endDate !== '' ? new Date(endDate).toISOString() : '',
      };

      if (category === Constants.GOAL.CATEGORIES.VISA_DURATION) {
        data.visaType = visaType;
        data.toDate = new Date(moment(startDate).add(+e.days, 'd')).toISOString();
      }
      getData(data);
      setThresholdValue('');
      setValueError(false);
      hide();
    } else {
      setValueError(true);
    }
  };

  const formatOptionLabel = ({ label }) => (
    <div style={{ display: 'flex' }}>
      <div>{renderFlag(label, countries)}</div>
    </div>
  );

  return (
    <>
      <LoaderInline loader={loading} />
      <Row className="pt-1 border-bottom font-san">
        <Col
          md={Constants.GOAL.CATEGORIES.CUSTOM_GOAL !== category ? 5 : 12}
          className="fs-18 fw-500 pt-3 ps-4 fs-16 fw-700 text-sidebar pb-2"
        >
          {title}
        </Col>
        {Constants.GOAL.CATEGORIES.ESTABLISH_RESIDENCY === category && (
          <Col md="7" className="d-flex justify-content-evenly pt-3">
            <div
              className={
                Constants.GOAL.TYPES.ACHIEVE === type
                  ? 'fs-16 fw-600 pointer border-bottom border-primary text-primary '
                  : 'fs-16 fw-400 pointer'
              }
              onClick={() => setType(Constants.GOAL.TYPES.ACHIEVE)}
            >
              Achieve Residency
            </div>
            <div
              className={
                Constants.GOAL.TYPES.AVOID === type
                  ? 'fs-16 fw-600 pointer border-bottom border-primary text-primary'
                  : 'fs-16 fw-400 pointer'
              }
              onClick={() => setType(Constants.GOAL.TYPES.AVOID)}
            >
              Avoid Residency
            </div>
          </Col>
        )}
        {Constants.GOAL.CATEGORIES.VISA_DURATION === category && (
          <Col md="7" className="d-flex justify-content-evenly pt-3">
            {Object.values(Constants.VISA_TYPES).map((item, idx) => (
              <div
                className={
                  visaType === item
                    ? 'fs-16 fw-600 pointer border-bottom border-primary text-primary '
                    : 'fs-16 fw-400 pointer'
                }
                onClick={() => setVisaType(item)}
              >
                {item}
              </div>
            ))}
          </Col>
        )}
      </Row>
      <Row>
        <Col md="12" sm="12">
          {Constants.GOAL.CATEGORIES.VISA_DURATION !== category && (
            <Col md="12" className=" px-md-4 mb-2 mt-4">
              <Form.Label className="fs-16 fw-600 w-100 d-block font-san ">Set Location</Form.Label>
              <ButtonGroup className="bg-lightBlue rounded-pill mt-2 mb-1 custom-toggle-btn">
                {locationType.map((item, idx) => (
                  <ToggleButton
                    key={'location' + idx}
                    id={`location-${idx}`}
                    type="radio"
                    name="location"
                    className={
                      toggleOption === item.value
                        ? 'btn-sidebar text-white border-0 br-20 px-5 shadow-none fs-16 fw-600'
                        : 'px-5 bg-lightBlue text-black border-0 shadow-none  br-20 fs-16 fw-300'
                    }
                    value={item.value}
                    checked={toggleOption === item.value}
                    onChange={(e) => {
                      setToggleOption(e.target.value);
                    }}
                  >
                    {item.label}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
          )}
        </Col>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Col md="12" sm="12" className="px-md-4 mb-3">
            {Constants.GOAL.CATEGORIES.VISA_DURATION === category && (
              <Form.Label className="fs-16 fw-600 w-100 d-block font-san mb-3 mt-4">Set Location</Form.Label>
            )}
            <Form.Group className="text-start">
              <div className="mb-3">
                <Controller
                  control={control}
                  name="country"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={sortData(countries, 'name')}
                      styles={REACT_SELECT_BASIC_GOAL_CUSTOM_STYLE}
                      placeholder="Select Country"
                      formatOptionLabel={formatOptionLabel}
                      onChange={(e) => {
                        field.onChange(e);
                        setSelectedCountries(e);
                      }}
                      value={selectedCountries}
                    />
                  )}
                />
                {errors.country && errors.country.type === 'required' && (
                  <div className="required text-danger fs-14">Country is Required.</div>
                )}
              </div>
              {toggleOption === Constants.LOCATION.TYPES.STATE || toggleOption === Constants.LOCATION.TYPES.CITY ? (
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="state"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={sortData(states, 'name')}
                        styles={REACT_SELECT_BASIC_GOAL_CUSTOM_STYLE}
                        placeholder="Select State / Region"
                        onChange={(e) => {
                          field.onChange(e);
                          setSelectedState(e);
                        }}
                        value={selectedState}
                      />
                    )}
                  />
                  {errors.state && errors.state.type === 'required' && (
                    <div className="required text-danger fs-14">State / Region is Required.</div>
                  )}
                </div>
              ) : null}
              {toggleOption === Constants.LOCATION.TYPES.CITY && (
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="city"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={sortData(cities, 'name')}
                        styles={REACT_SELECT_BASIC_GOAL_CUSTOM_STYLE}
                        placeholder="Select City"
                        onChange={(e) => {
                          field.onChange(e);
                          setSelectedCity(e);
                        }}
                        value={selectedCity}
                      />
                    )}
                  />
                  {errors.city && errors.city.type === 'required' && (
                    <div className="required text-danger fs-14">City is Required.</div>
                  )}
                </div>
              )}
            </Form.Group>
          </Col>
          {/* {category !== Constants.GOAL.CATEGORIES.VISA_DURATION && ( */}
          <Row className="px-md-4 mb-3 mt-4">
            <Col md="12" sm="12">
              {category === Constants.GOAL.CATEGORIES.VISA_DURATION ? (
                <Form.Label className="fs-16 fw-600 font-san mb-3">Set visa duration</Form.Label>
              ) : (
                <Form.Label className="fs-16 fw-600 font-san mb-3">Set number of days</Form.Label>
              )}
            </Col>
            <Col md="12" sm="12">
              <InputGroup>
                <Form.Control
                  type="number"
                  className="border-0 shadow-none br-0 fs-18 fw-400 px-0  border-bottom-onboarding py-2 goals-onboarding-placeholder"
                  value={thresholdValue}
                  placeholder={category !== Constants.GOAL.CATEGORIES.VISA_DURATION ? 'Enter Goal' : 'Number of days'}
                  onChange={(e) => {
                    setThresholdValue(e.target.value);
                  }}
                />
                {/* <Form.Control
                  className="border-0 shadow-none br-0 fs-18 fw-400 px-0  border-bottom-onboarding py-2 goals-onboarding-placeholder"
                  type="number"
                  defaultValue={thesholdValue}
                  placeholder={
                    category !== Constants.GOAL.CATEGORIES.VISA_DURATION
                      ? "Enter Goal"
                      : "Number of days"
                  }
                  {...register("days", {
                    required: "Number of days is required.",
                  })}
                /> */}

                <InputGroup.Text
                  id="password-field"
                  className="bg-none border-0 right-0 position-relative mt-2 border-bottom-onboarding br-0"
                >
                  {category !== Constants.GOAL.CATEGORIES.VISA_DURATION && (
                    <div>
                      <AiOutlineInfoCircle
                        className="pointer fs-5 z-999 text-primary"
                        onClick={() => setInfoVisible(!infoVisible)}
                      />
                      {infoVisible && (
                        <div
                          className="position-absolute bg-lightGrey  br-15"
                          style={{
                            width: '330px',
                            right: '0',
                            whiteSpace: 'break-spaces',
                            height: 'auto',
                            padding: '35px 30px',
                            top: '40px',
                          }}
                        >
                          <AiOutlineClose
                            className="position-absolute right-15 top-0 mt-3 fs-5 pointer"
                            onClick={() => setInfoVisible(!infoVisible)}
                          />

                          <p className="fs-16 fw-400 m-0 p-0 font-san">
                            This is a common goal for this location but feel free to change it based on your needs
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </InputGroup.Text>
                {thresholdValue === '' && valueError && (
                  <div className="required text-danger text-start fs-14 w-100">Number of days is required.</div>
                )}
              </InputGroup>
            </Col>
          </Row>
          <Col md="12" sm="12" className="px-md-4 mb-3 mt-4">
            {category !== Constants.GOAL.CATEGORIES.VISA_DURATION && (
              <div className="w-100">
                <Form.Label className="fs-16 fw-600 font-san w-100 d-block mb-3">Set Start Date</Form.Label>
                <Form.Group className="mb-1 col-md-6 col-sm-12 w-100 ">
                  <div className='d-flex'>
                    <div  className="me-5">
                  <Controller
                    control={control}
                    name="date"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        popperClassName="single-calender"
                        className=" border-0 br-0 border-bottom-onboarding shadow-none w-100 fs-16 fw-500 py-2 px-0 text-indent-0 goals-onboarding-placeholder"
                        placeholderText="Select From date"
                        dateFormat={dateFormat}
                        onChange={(date) => {
                         field.onChange(date);
                         setStartDate(date);
                        }}
                        selected={startDate}
                        startDate={startDate}
                      />
                    )}
                  />
                  </div>
                  <div>
                  <Controller
                    control={control}
                    name="date"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        popperClassName="single-calender"
                        className=" border-0 br-0 border-bottom-onboarding shadow-none w-100 fs-16 fw-500 py-2 px-0 text-indent-0 goals-onboarding-placeholder"
                        placeholderText="Select To date"
                        dateFormat={dateFormat}
                        onChange={(date) => {
                           setEndDate(date);
                        }}
                        selected={endDate}
                        endDate={endDate}
                      />
                    )}
                  />
                  </div>
                  </div>
                  {errors.date && errors.date.type === 'required' && (
                    <div className="required text-danger text-start fs-14 w-100 d-block">From date and To date is required.</div>
                  )}
                </Form.Group>
              </div>
            )}
            {category === Constants.GOAL.CATEGORIES.VISA_DURATION && (
              <div className="w-100">
                <Form.Label className="fs-16 fw-600 font-san w-100 d-block mb-3">Set Start Date</Form.Label>
                <Form.Group className="mb-1 col-md-6 col-sm-12 w-100">
                  <Controller
                    control={control}
                    name="date"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DatePicker
                        popperClassName="single-calender"
                        className=" border-0 border-bottom-onboarding shadow-none w-100 fs-16 fw-500 py-2 text-indent-0 goals-onboarding-placeholder"
                        placeholderText="Starting from"
                        dateFormat={dateFormat}
                        onChange={(date) => {
                          field.onChange(date);
                          setStartDate(date);
                        }}
                        selected={startDate}
                      />
                    )}
                  />
                  {errors.date && errors.date.type === 'required' && (
                    <div className="required text-danger text-start fs-14">From date is required.</div>
                  )}
                </Form.Group>
              </div>
            )}
          </Col>
          <Row className="px-md-4 mb-3 mt-4 align-items-center">
            <Col md="8">
              {Constants.GOAL.CATEGORIES.ESTABLISH_RESIDENCY === category && (
                <p className="text-chronoOrange fs-14 p-0 m-0" style={{ width: '94%' }}>
                  There are often multiple ways to achieve tax residency. Please consult your tax professional for the
                  correct goals for you.
                </p>
              )}
            </Col>
            <Col md="4">
              <Button
                type="submit"
                variant="primary"
                onClick={() => handleSubmit()}
                className="text-white d-block mx-auto br-20 px-5 fs-16 fw-400"
              >
                Set Goal
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};

export const REACT_SELECT_BASIC_GOAL_CUSTOM_STYLE = {
  option: (provided, state) => ({
    ...provided,
    padding: '15px 30px',
    color: '#000034',
    textAlign: 'left',
    borderBottom: '0.5px solid #D8D8D8',
    zIndex: 5,
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Open Sans',
  }),

  placeholder: (provided) => ({
    ...provided,
    color: '#2C2C2C',
    fontSize: '16px',
    fontWeight: 300,
  }),
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    borderBottom: '1px solid #989898',
    outline: 0,
    display: 'flex',
    // height: "38px",
    textAlign: 'left',
    zIndex: 5,
    padding: '3.5px 0px',
    fontSize: '16px',
    fontWeight: 500,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
  menu: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: '15px',
    zIndex: 5,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: '15px',
    textAlign: 'left',
    zIndex: 5,
    background: 'rgba(237, 237, 237, 0.42)',
  }),
};
