import { useCallback, useState } from "react";
import { Button, Form, Image, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as onboardService from "../../services/onboard";
import * as alertActions from "../../actions/alert";
import * as loaderActions from "../../actions/loader";
import {
  // backToLogin,
  // passwordCheck,
  passwordPattern,
} from "../../helpers/utility";
import { useForm } from "react-hook-form";
import chonoIcon from "../../assets/img/chonoIcon.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import { RiCloseLine } from "react-icons/ri";
// import { FaCheck } from "react-icons/fa";

const SecondStep = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = watch("password");
  const [iconVisible, setIconVisible] = useState(false);
  const [iconVisible1, setIconVisible1] = useState(false);
  // const [passwordState, setPasswordState] = useState(false);
  const onSubmit = useCallback(
    (e) => {
      const data = {
        email: e.email,
        password: e.password,
      };
      props.setEmailId(data);
      dispatch(loaderActions.loader(true));
      onboardService.signup(data).then((response) => {
        if (response.status_code === 200) {
          dispatch(alertActions.success("Passcode sent successfully."));
          sessionStorage.setItem("userData" , JSON.stringify(data));
          sessionStorage.setItem('step' , 3);
          props.nextStep();
        } else if (response.error !== null) {
          dispatch(alertActions.error(response.error));
        }
        dispatch(loaderActions.loader(false));
      });
    },
    [dispatch, props]
  );

  return (
    <div
      style={{ marginTop: "-95px" }}
      className="d-flex flex-column min-vh-100 justify-content-center align-items-center font-san"
    >
      <h2 className="text-pureOrange pb-2 fw-600 font-san">
        Welcome to Chrono
      </h2>
      <h3 className="text-white pb-4 fw-400  font-san">
        We’re glad you’re here. <Image src={chonoIcon} className="img-fluid" />
      </h3>
      <div className="bg-white shadow-none login-form border-onboarding   px-4 br-15 mt-3">
        <p className="pt-3 pb-4 fs-18 fw-600 font-san">
          Let’s get you started.{" "}
        </p>
        <Form className="mb-2" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              className="border-0 shadow-none bg-none br-0 px-0 py-3 fs-18 fw-300 border-bottom-onboarding"
              placeholder="Email Address"
              autoComplete="new-email"
              {...register("email", {
                required: "Email Address is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email.",
                },
              })}
            />
            {errors.email !== undefined && (
              <div className="required text-danger">{errors.email.message}</div>
            )}
          </Form.Group>
          <InputGroup className="mb-3">
            <Form.Control
              type={iconVisible ? "text " : "password"}
              className="border-0  shadow-none bg-none br-0 px-0 w-100 py-3 fs-18 fw-300 border-bottom-onboarding"
              // onFocus={() => setPasswordState(true)}
              // onPointerLeave={() => setPasswordState(false)}
              placeholder="Create Password"
              autoComplete="new-password"
              {...register("password", {
                required: "Create Password is required.",
                pattern: {
                  value: passwordPattern,
                  message:
                    "Create Password must be one upper case , one lower case , one number and a special character.",
                },
              })}
            />
            <InputGroup.Text
              id="password-field"
              className="bg-none border-0 right-0 position-absolute mt-2"
            >
              {!iconVisible ? (
                <AiOutlineEye
                  className="pointer fs-5 z-999 fs-3"
                  onClick={() => setIconVisible(true)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="pointer fs-5 z-999 fs-3"
                  onClick={() => setIconVisible(false)}
                />
              )}
            </InputGroup.Text>
            {/* {passwordState && (
              <div>
                <ul className="list-unstyled p-0 ps-3 pt-2 ">
                  <li>
                    {passwordCheck(password).length ? (
                      <FaCheck className="fs-6 text-success fw-800 me-3 ms-1" />
                    ) : (
                      <RiCloseLine className="fs-4 text-danger fw-800 me-2" />
                    )}
                    8-20 Characters
                  </li>
                  <li>
                    {" "}
                    {passwordCheck(password).uppercase ? (
                      <FaCheck className="fs-6 text-success fw-800 me-3 ms-1" />
                    ) : (
                      <RiCloseLine className="fs-4 text-danger fw-800 me-2" />
                    )}
                    At least one capital letter
                  </li>
                  <li>
                    {" "}
                    {passwordCheck(password).number ? (
                      <FaCheck className="fs-6 text-success fw-800  me-3 ms-1" />
                    ) : (
                      <RiCloseLine className="fs-4 text-danger fw-800  me-2" />
                    )}
                    At least one number
                  </li>
                  <li>
                    {" "}
                    {passwordCheck(password).special ? (
                      <FaCheck className="fs-6 text-success fw-800  me-3 ms-1" />
                    ) : (
                      <RiCloseLine className="fs-4 text-danger fw-800  me-2" />
                    )}
                    At least one special charcter
                  </li>
                </ul>
              </div>
            )} */}
            {errors.password !== undefined && (
              <div className="required text-danger">
                {errors.password.message}
              </div>
            )}
          </InputGroup>
          <InputGroup className="mb-5 pb-3">
            <Form.Control
              type={iconVisible1 ? "text" : "password"}
              className="border-0  shadow-none bg-none br-0 px-0 w-100 py-3 fs-18 fw-300 border-bottom-onboarding"
              placeholder="Re-enter Password"
              autoComplete="re-new-password"
              {...register("repassword", {
                required: "Re-enter password is required.",
                validate: (value) =>
                  value === password ||
                  "Re-enter Password don't match with create password.",
              })}
            />
            <InputGroup.Text
              id="password-field"
              className="bg-none border-0 right-0 position-absolute mt-2"
            >
              {!iconVisible1 ? (
                <AiOutlineEye
                  className="pointer fs-5 z-999 fs-3"
                  onClick={() => setIconVisible1(true)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="pointer fs-5 z-999 fs-3"
                  onClick={() => setIconVisible1(false)}
                />
              )}
            </InputGroup.Text>
            {errors.repassword !== undefined && (
              <div className="required text-danger d-block">
                {errors.repassword.message}
              </div>
            )}
          </InputGroup>
          <Button
            type="submit"
            variant="primary"
            className="mt-5 d-block mx-auto px-5 br-20 text-white fs-18 fw-400 mb-5"
          >
            Next
          </Button>
        </Form>
        {/* <div className="text-center">
          <p className="  fs-18 fw-400 pb-4">
            Already a user?{" "}
            <Button
              variant="link"
              className="shadow-none text-decoration-none fs-18 fw-300"
              onClick={() => backToLogin()}
            >
              Login
            </Button>
          </p>
        </div> */}
      </div>
    </div>
  );
};
export default SecondStep;
