const CustomGoalIcon = ({className , size}) => {
  return (
    <svg
      className={className}
      width={size !== undefined && size ? '44px' : '28px'}
      height={size !== undefined && size ? '40px' : '21px'}
      viewBox="0 0 28 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1915 15.0133C16.12 15.0133 17.0096 14.7291 17.7635 14.1917C17.9981 14.0244 18.0529 13.6984 17.886 13.4641C17.7184 13.2295 17.3927 13.1744 17.1584 13.3416C16.5816 13.7523 15.9015 13.9693 15.1915 13.9693C14.5211 13.9693 13.8729 13.7746 13.3169 13.4055C13.0767 13.2465 12.7534 13.3118 12.5937 13.552C12.4347 13.7918 12.5 14.1158 12.7402 14.2751C13.4675 14.7578 14.3151 15.0133 15.1915 15.0133Z"
        fill="currentColor"
      />
      <path
        d="M21.319 13.7529C21.1154 13.5493 20.7848 13.5493 20.5809 13.7529C20.377 13.9565 20.3773 14.2869 20.5809 14.491L22.3041 16.2145L20.8334 17.6854L19.1102 15.962C18.9392 15.7909 18.6726 15.7599 18.4667 15.887C17.8982 16.2379 17.2813 16.4936 16.6331 16.6471C16.3979 16.7027 16.2315 16.9127 16.2315 17.1547V19.5914H14.1507V17.1547C14.1507 16.9127 13.985 16.7027 13.7492 16.6471C13.101 16.4939 12.4844 16.2382 11.9156 15.887C11.7097 15.7596 11.4437 15.7909 11.2726 15.962L9.54948 17.6854L8.07763 16.2145L9.80138 14.491C10.005 14.2871 10.005 13.9567 9.80138 13.7529C9.59781 13.549 9.26771 13.5493 9.06326 13.7529L6.97104 15.8454C6.76747 16.0493 6.76747 16.3797 6.97104 16.5835L9.17984 18.7926C9.38399 18.9962 9.7141 18.9962 9.91796 18.7926L11.7269 16.9836C12.1669 17.2218 12.6285 17.413 13.1074 17.5557V20.1133C13.1074 20.4015 13.3409 20.6353 13.6291 20.6353H16.7532C17.0414 20.6353 17.2749 20.4015 17.2749 20.1133V17.5554C17.7532 17.4127 18.2154 17.2212 18.6553 16.9833L20.4643 18.7923C20.6679 18.9959 20.9986 18.9959 21.2024 18.7923L23.4118 16.5832C23.6154 16.3797 23.6154 16.0493 23.4118 15.8451L21.319 13.7529Z"
        fill="currentColor"
      />
      <path
        d="M15.1916 5.62183C14.2625 5.62183 13.3729 5.90565 12.619 6.44284C12.3844 6.61009 12.3296 6.9358 12.4966 7.17042C12.5985 7.31336 12.759 7.3898 12.9221 7.3898C13.0267 7.3898 13.1327 7.35846 13.2247 7.29285C13.8009 6.88249 14.481 6.66515 15.1916 6.66515C15.8614 6.66515 16.5096 6.86023 17.0656 7.229C17.3058 7.38863 17.6297 7.32302 17.7888 7.08284C17.9484 6.84295 17.8825 6.5187 17.6429 6.35936C16.9153 5.87665 16.0677 5.62183 15.1916 5.62183Z"
        fill="currentColor"
      />
      <path
        d="M20.5812 6.88211C20.7847 7.08568 21.1154 7.08568 21.3193 6.88211L23.4121 4.78959C23.6157 4.58573 23.6157 4.25533 23.4121 4.05118L21.2027 1.84208C20.9992 1.63851 20.6685 1.63851 20.4646 1.84208L18.6556 3.65136C18.2157 3.41323 17.7535 3.22167 17.2752 3.07902V0.521665C17.2752 0.233446 17.0417 0 16.7535 0H13.6291C13.3409 0 13.1074 0.233446 13.1074 0.521665V3.07902C12.6285 3.22167 12.1669 3.41323 11.7269 3.65136L9.91796 1.84208C9.71439 1.63851 9.38428 1.63851 9.17984 1.84208L6.97104 4.05118C6.76747 4.25504 6.76747 4.58543 6.97104 4.78959L9.06326 6.88211C9.16578 6.98404 9.29905 7.03471 9.43232 7.03471C9.56618 7.03471 9.69945 6.98374 9.80138 6.88211C10.005 6.67824 10.005 6.34785 9.80138 6.14398L8.07763 4.42053L9.54948 2.94926L11.2726 4.67301C11.4437 4.84407 11.7097 4.87483 11.9156 4.7477C12.4841 4.3968 13.101 4.1411 13.7492 3.98791C13.9849 3.93226 14.1507 3.72195 14.1507 3.48001V1.04362H16.2315V3.48001C16.2315 3.72195 16.3979 3.93226 16.6331 3.98791C17.2813 4.1408 17.8979 4.3968 18.4667 4.7477C18.6726 4.87512 18.9392 4.84407 19.1102 4.67301L20.8334 2.94926L22.304 4.42053L20.5809 6.14398C20.3773 6.34785 20.3773 6.67824 20.5812 6.88211Z"
        fill="currentColor"
      />
      <path
        d="M27.4777 7.86377H23.0326H6.20491C6.19407 7.86377 6.18382 7.86494 6.17298 7.86553C6.16712 7.86582 6.16156 7.86582 6.1557 7.86641C6.11616 7.87021 6.0772 7.87841 6.04059 7.89072C6.03942 7.8913 6.03766 7.8913 6.03649 7.89189L0.353537 9.82243C0.142352 9.89448 0 10.0934 0 10.3166C0 10.54 0.142352 10.7386 0.353537 10.811L6.03649 12.7412C6.03825 12.7418 6.04 12.7424 6.04117 12.7427C6.04908 12.7456 6.0567 12.7477 6.06402 12.7494C6.07193 12.7518 6.08072 12.7538 6.08862 12.7556C6.09536 12.757 6.10239 12.7585 6.10913 12.7603C6.11879 12.762 6.12905 12.7635 6.13901 12.7647C6.14486 12.7655 6.15043 12.7664 6.15629 12.7667C6.1724 12.7682 6.18821 12.7693 6.20432 12.7693H23.0326H27.4777C27.7666 12.7693 28 12.5356 28 12.2477V8.38543C28 8.24718 27.9449 8.1145 27.8474 8.01637C27.7493 7.91884 27.6166 7.86377 27.4777 7.86377ZM22.511 11.7254H6.72657V10.3163V8.90768H22.511V11.7254ZM5.68295 9.11418V10.3163L5.68237 11.5184L2.14407 10.3163L5.68295 9.11418ZM26.9558 11.7254H23.5543V8.90798L26.9558 8.90768V11.7254Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default CustomGoalIcon;
