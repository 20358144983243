import { Storage } from "./storage";

export const isExpired = () => {
  let isExpired = false;

  if (Storage.getToken() !== null && JSON.parse(Storage.getToken()) !== null) {
    var dateNow = new Date();
    const jwt = parseJwt(JSON.parse(Storage.getToken()).token);
    const exp = new Date(jwt.exp * 1000);
    if (exp < dateNow.getTime()) {
      isExpired = true;
    }
  }
  return isExpired;
};

export const isExpiredWithToken = (token) => {
  let isExpired = false;

  if (token !== null && token !== undefined) {
    var dateNow = new Date();
    const jwt = parseJwt(token);
    const exp = new Date(jwt.exp * 1000);
    if (exp < dateNow.getTime()) {
      isExpired = true;
    }
  }
  return isExpired;
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
