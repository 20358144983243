import { Image, Button, Form } from "react-bootstrap";
import workProgress from "../../assets/img/workProgress.svg";
import { AiOutlineClose } from "react-icons/ai";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import SucessImg from "../../assets/img/success.png";

const NotAvailable = ({ close, invitePendingCountry }) => {
  const [signUp, setSignUp] = useState(false);

  useEffect(() => {
    setSignUp(false);
  }, []);

  return (
    <div className="font-san">
      <div className="text-end">
        <AiOutlineClose
          onClick={() => close()}
          className="text-black pointer fs-3"
        />
      </div>
      {signUp ? (
        <NotifyMe
          invitePendingCountry={(e) => invitePendingCountry(e)}
          close={() => close()}
        />
      ) : (
        <>
          <Image
            src={workProgress}
            className="img-fluid d-block mx-auto mt-2"
          />
          <h5 className="text-center fw-600 text-sidebar fs-24 py-3 ">
            Sorry!
          </h5>
          <p className="text-center text-sidebar fw-600 mx-auto login-form fs-20">
            Chrono is not yet available in your country. If you'd like us to
            notify you when it is available, please provide your email address
            below.
          </p>
          <Button
            variant="sidebar"
            onClick={() => setSignUp(true)}
            className="rounded-pill d-block mx-auto px-5 py-2 mt-4 fs-18 fw-600"
          >
            Notify Me
          </Button>
        </>
      )}
    </div>
  );
};
export default NotAvailable;

const NotifyMe = ({ invitePendingCountry, close }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [finish, setFinish] = useState(false);

  const onSubmit = (e) => {
    invitePendingCountry(e);
    setFinish(true);
  };
  return finish ? (
    <div>
      <Image src={SucessImg} className="img-fluid d-block mx-auto py-2" />
      <p className="text-center text-sidebar fw-600 w-75 mx-auto mt-3">
        We will notify you once we are available in your country!
      </p>
      <Button
        onClick={() => close()}
        variant="sidebar"
        className="br-20 d-block mx-auto px-5 mt-4"
      >
        Close
      </Button>
    </div>
  ) : (
    <div>
      <p className="text-center text-sidebar fw-700 w-75 mx-auto mt-3 fs-24">
      Notify Me When Chrono Is Available In My Country!
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control
          type="email"
          placeholder="Enter your email address"
          {...register("email", {
            required: "Email address is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          className="border-0 border-bottom br-0 w-75 mx-auto d-block  mt-4 mb-3 shadow-none"
          isInvalid={!!errors.email}
        />
        {errors.email !== undefined && (
          <Form.Control.Feedback type="invalid" className="text-center">
            {errors.email.message}
          </Form.Control.Feedback>
        )}
        <p className="text-center">
          Chrono is committed to protecting and respecting your privacy, and
          we'll only use your personal information to administer your account
          and to provide information related to our products & services. If you
          consent, please tick the box below:
        </p>
        <p className="d-flex fs-14">
          <input
            type="checkbox"
            {...register("check", {
              required: "Please agree other communications from Chrono.",
            })}
          />
          <label>I agree to receive other communications from Chrono.</label>
        </p>
        {errors.check !== undefined && (
          <div className="required text-danger text-center">
            {errors.check.message}
          </div>
        )}
        <Button
          type="submit"
          variant="sidebar"
          className="br-20 d-block mx-auto px-5 mt-4 fs-18 fw-400"
        >
          Notify me
        </Button>
      </Form>
    </div>
  );
};
