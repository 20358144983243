import { Row, Col, Button, Image } from "react-bootstrap";
import { backToLogin } from "../../helpers/utility";
import HeaderBg from "../../assets/img/men.png";
const PageNotFound = () => {
  return (
    <>
      <Row className="h-100">
        <Col className="page-not-found text-center" md="12">
          <div className="modal-page">
            <div className="content">
              <h1 className="mb-4">404</h1>
              <h4 className="mb-5">Page not found</h4>
              <p className="text-center mb-5">Looks like you are lost<br/>the page you are looking for is not available. </p>
              <Button variant="primary" className="px-5 pointer" onClick={() => backToLogin()}>Go to Home</Button>
            </div>
            <Image className="pg-img" src={HeaderBg} />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PageNotFound;
