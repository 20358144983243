import axios from "axios";
import { Storage } from ".";

const baseURL = process.env.REACT_APP_API;
const createAxiosClient = () => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use((config) => {
    config.headers["Content-Type"] = "application/json";
    config.headers.Accept = "application/json,text/plain";

    const accessToken = JSON.parse(Storage.getToken());

    try {
      if (accessToken.token) {
        config.headers.Authorization = `Bearer ${accessToken.token}`;
      }
      return config;
    } catch (err) {
      // return original config if JSON.parse fails
      return config;
    }
  });

  // instance.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     const originalConfig = error.config;
  //     if (error.response) {
  //       if (error.response.status === 401 && !originalConfig._retry ) {
  //         originalConfig._retry = true;
  //         try {
  //           const token = JSON.parse(Storage.getRefreshToken()).token;
  //           instance.post("auth/refresh-token", {} , { headers: {"Authorization" : `Bearer ${token}`} }).then((response) => {
  //             if(response.status === 200){
  //               Storage.setToken(JSON.stringify(response.data.data.access));
  //               Storage.setRefreshToken(JSON.stringify(response.data.data.refresh))
  //               window.location.reload();
  //               return;
  //               //return instance(originalConfig);
  //             }
  //           }, error => {
  //            return Promise.reject(error);
  //           });
  //           // return instance(originalConfig);
  //         } catch (_error) {
  //           if (_error.response && _error.response.data) {
  //             return Promise.reject(_error.response.data);
  //           }
  //         }
  //       if (error.response.status !== 401) {
  //         return Promise.reject(error.response.data);
  //       }
  //     }
  //   }
  //     return Promise.reject(error);
  //   });

  return instance;
};

export default createAxiosClient();
