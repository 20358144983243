import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";

const Loader = ({ loading }) => {
  return loading ? (
    <Row className="p-0 m-0 ">
      <Col className="loading">
        <Spinner
          className="page-loader"
          animation="grow"
          variant="light"
          role="status"
        ></Spinner>
      </Col>
    </Row>
  ) : null;
};
export default Loader;
