import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = ({show, onHide, acceptConfirm, title , deleteEnable }) => {
    return (
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="text-center br-20">
          <h4>{title}</h4>
          <br/>

          {deleteEnable === true ?
           <Button className="bg-lightRed text-danger text-nowrap border-danger rounded-pill  px-3 fw-600 " onClick={acceptConfirm}><i className="iconly-Delete mx-1 fw-600"></i>Delete</Button>:
           <Button variant="primary" className="text-white px-5 br-20" onClick={acceptConfirm}>Yes</Button>
           }
         
          &nbsp;&nbsp;
          <Button variant="primary" className="text-white px-5 br-20" onClick={onHide}>No</Button>
        </Modal.Body>
      </Modal>
    );
  }
  export default ConfirmModal;