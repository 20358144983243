import { createActionType } from "../actions/actionBase";

export const GOALS = {
  ADD: "ADD_GOAL",
  CLEAR: "CLEAR_GOAL"
}

export const ACCOUNT = {
  LOGIN: createActionType("ACCOUNT__LOGIN"),
  AUTO_LOGIN: "AUTO__ACCOUNT__LOGIN",
  LOGOUT: createActionType("ACCOUNT__LOGOUT"),
};

export const Loader = {
  LOADER: "LOADER_APP",
};

export const Stage = {
  STAGE: "STAGE_APP"
}

export const MapDetails = {
   DATA :"DATA_APP",
   CLEAR:"CLEAR_APP"
}

export const LocationDetails = {
   LOCATION : "LOCATION_APP",
   CLEAR: "CLEAR_APP"
}

export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  INFO: 'ALERT_INFO',
  WARNING: 'ALERT_WARNING',
  CLEAR: 'ALERT_CLEAR'
};