const STORAGE = localStorage;
const USER = "user";
const TOKEN = "token";
const REFRESH_TOKEN = "refreshToken";
const REGISTER = "register";
const COUNTRY = "COUNTRY";
const MODAL_TIME = "modalTime";
const STEPS = "step";

export class Storage {
  static removeToken() {
    STORAGE.removeItem(TOKEN);
  }

  static removeRefreshToken() {
    STORAGE.removeItem(REFRESH_TOKEN);
  }

  static getSession() {
    try {
      return JSON.parse(STORAGE.getItem(USER));
    } catch (e) {
      return null;
    }
  }

  static setSessionRegister(data) {
    this.setToken(JSON.stringify(data.tokens.access));
    this.setRefreshToken(JSON.stringify(data.tokens.refresh));
    STORAGE.setItem(USER, JSON.stringify(data.user));
  }

  static setSession(data) {
    this.setToken(JSON.stringify(data.token.access));
    this.setRefreshToken(JSON.stringify(data.token.refresh));
    STORAGE.setItem(USER, JSON.stringify(data.user));
  }

  static updateSession(data) {
    STORAGE.removeItem(USER);
    STORAGE.setItem(USER, JSON.stringify(data));
  }

  static setRegisterStatus(data) {
    STORAGE.setItem(REGISTER, JSON.stringify(data));
  }
  static getRegisterStatus() {
    try {
      return JSON.parse(localStorage.getItem("register"));
    } catch (e) {
      return null;
    }
  }

  static setSelectedCountry(data) {
    STORAGE.setItem(COUNTRY, JSON.stringify(data));
  }
  static getSelectedCountry() {
    try {
      return JSON.parse(localStorage.getItem(COUNTRY));
    } catch (e) {
      return null;
    }
  }

  static getStep() {
    return STORAGE.getItem(STEPS);
  }

  static setStep(step) {
    STORAGE.setItem(STEPS, step);
  }

  static getToken() {
    return STORAGE.getItem(TOKEN);
  }

  static setToken(token) {
    STORAGE.setItem(TOKEN, token);
  }

  static getRefreshToken() {
    return STORAGE.getItem(REFRESH_TOKEN);
  }

  static setRefreshToken(refresh) {
    STORAGE.setItem(REFRESH_TOKEN, refresh);
  }

  static getIdleModalTime() {
    try {
      return JSON.parse(localStorage.getItem(MODAL_TIME));
    } catch (e) {
      return null;
    }
  }

  static setIdleModalTime(stage) {
    STORAGE.setItem(MODAL_TIME, stage);
  }

  static removeAll() {
    sessionStorage.removeItem('step');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('residencyGoal')
    sessionStorage.removeItem('visaGoal');
    sessionStorage.removeItem('customGoal')
    STORAGE.removeItem(USER);
    this.removeToken();
    this.removeRefreshToken();
  }
}
