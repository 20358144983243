import { Button, Modal } from "react-bootstrap";

const ModalComponent = ({
  headerTitle,
  title,
  show,
  onHide,
  data,
  fields,
  buttonTitle,
  modalClass,
  type,
  component,
  size,
}) => {
  const closeModal = () => {
    onHide(false);
  };

  return (
    <Modal
      className={modalClass}
      show={show}
      onHide={closeModal}
      size={size !== undefined ? size : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title ? (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="h5">
            {title}
          </Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body>
        {headerTitle ? <h5>{headerTitle}</h5> : null}
        {type === "component" ? component : null}
      </Modal.Body>
      {buttonTitle !== "" && buttonTitle !== undefined ? (
        <Modal.Footer className="justify-content-center border-0">
          <Button variant="primary" type="submit">
            {" "}
            buttonTitle{" "}
          </Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};
export default ModalComponent;
