import {
  Button,
  // ButtonGroup,
  Col,
  Form,
  Image,
  Row,
  // ToggleButton,
} from "react-bootstrap";
import iphoneLogo from "../../assets/img/iphone.png";
import googleStore from "../../assets/img/googlePlay.svg";
import appleStore from "../../assets/img/appleStore.svg";
import * as onboardService from "../../services/onboard";
import { useDispatch, useSelector } from "react-redux";
import * as alertActions from "../../actions/alert";
// import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import SuccessImg from "../../assets/img/success.png";
import { history, Storage } from "../../helpers";
import { backToLogin } from "../../helpers/utility";
// import * as authActions from "../../actions/auth";
// import { isExpired } from "../../helpers/auth.helper";
import * as loaderActions from "../../actions/loader";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js/min";
// import { isValidPhoneNumber } from "libphonenumber-js/min";

const SevenStep = (props) => {
  const dispatch = useDispatch();
  // const [type, setType] = useState(1);
  const [invite, setInvite] = useState(false);
  const userData = useSelector((state) => state.auth.loginData);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const userDataSession = JSON.parse(sessionStorage.getItem("user"));
  const [phoneNumber, setPhoneNumber] = useState(
    userDataSession !== null && userDataSession !== undefined
      ? userDataSession.countryCode + "" + userDataSession.mobile
      : ""
  );

  useEffect(() => {
    if (props.mobile !== "" && props.mobile !== undefined) {
      setPhoneNumber(props.mobile ? props.mobile : "");
    }
  }, [props]);

  // Invite User with Phone Number
  const onSubmit = (e) => {
    let number;
    if (!phoneNumber.startsWith("+")) {
      number = "+" + phoneNumber;
    } else {
      number = phoneNumber;
    }

    if (isValidPhoneNumber(number)) {
      const data = {
        mobile: phoneNumber,
      };
      onboardService.inviteUser(data).then((response) => {
        if (response.status_code === 200) {
          setInvite(true);
          Storage.removeAll();
          dispatch(alertActions.success("Invite link sent successfully."));
        } else if (response.status_code !== 200) {
          dispatch(alertActions.error(response.error));
        }
      });
    } else {
      dispatch(alertActions.error("Mobile Number is required."));
    }
  };

  useEffect(() => {
    if (authenticated && userData) {
      history.push("/login");
      dispatch(loaderActions.loader(true));
    }
  }, [authenticated, userData, dispatch]);

  // const typeStore = [
  //   { label: "Apple App Store", value: 1 },
  //   { label: "Google Play Store", value: 2 },
  // ];

  // const autoLogin = () => {
  //   if (!isExpired()) {
  //     Storage.setSelectedCountry(null);
  //     const access = JSON.parse(Storage.getToken());
  //     const refresh = JSON.parse(Storage.getRefreshToken());
  //     const user = Storage.getSession();
  //     const data = {
  //       token: {
  //         access: access,
  //         refresh: refresh,
  //       },
  //       user: user,
  //     };
  //     dispatch(authActions.autologin(data));
  //   }
  // };

  const linkRedirect = (link) => {
    window.location.href = link;
  }
  return (
    <Row
      style={{ marginTop: "-95px" }}
      className="d-flex flex-column min-vh-100 justify-content-center align-items-center g-0"
    >
      <Col md="12" sm="12" className="mt-5">
        <Image
          src={iphoneLogo}
          className="d-block mx-auto mt-4"
          height="280px"
        />
      </Col>
      {invite ? (
        <div>
          <h3 className="text-pureOrange fw-600 text-center mb-3 pb-3">
            A link to download the Chrono app has been sent to your phone.
          </h3>
          <Image
            src={SuccessImg}
            className="img-fluid my-3 d-block mx-auto mb-4"
          />
          <Button
            onClick={() => backToLogin()}
            className="text-white btn-primary btn-sm px-5 d-block mx-auto rounded-pill my-2 mb-3"
          >
            Finish
          </Button>
        </div>
      ) : (
        <Col md="12" className="text-center font-san">
          <h2 className="text-chronoOrange fw-600 pb-2">
            Let’s get the App on your phone!
          </h2>
          <p className="d-block mx-auto  fs-18 fw-300 text-white mb-4 pb-1">
           We’ll text you a link to install the App
            to your mobile phone.
          </p>
          {/* <ButtonGroup className="bg-lightBlue rounded-pill my-2">
            {typeStore.map((item, idx) => (
              <ToggleButton
                key={"date" + idx}
                id={`date-${idx}`}
                type="radio"
                name="date"
                className={
                  type === item.value
                    ? "bg-chronoOrange text-white border-0 br-20 px-4 shadow-none fs-16 fw-400"
                    : "px-4 bg-lightBlue text-black border-0 shadow-none  br-20 fs-16 fw-300"
                }
                value={item.value}
                checked={type === item.value}
                onChange={(e) => setType(+e.currentTarget.value)}
              >
                {item.label}
              </ToggleButton>
            ))}
          </ButtonGroup> */}
          <Form.Group style={{ width: "350px" }} className="mb-1 mt-4 mx-auto">
            <PhoneInput
              name="mobile"
              placeholder="Mobile Number"
              value={phoneNumber}
              containerClass="react-tel-input-white"
              country={
                Storage.getSelectedCountry() !== null
                  ? Storage.getSelectedCountry().isoCode.toLowerCase()
                  : "us"
              }
              autoFormat={true}
              enableSearch={true}
              disableSearchIcon={true}
              onChange={(phone) => {
                setPhoneNumber(phone);
              }}
            />
          </Form.Group>
          <Button
            onClick={() => onSubmit()}
            className="text-white btn-primary btn-sm px-5 d-block mx-auto rounded-pill mt-5 mb-5 fs-18 fw-400"
            id="phone-number"
            disable={(phoneNumber === "").toString()}
          >
            Send Link
          </Button>
        </Col>
      )}
      <Col md="12">
        <div style={{ maxWidth: "540px" }} className="mx-auto py-1 pt-1">
          <p className="p-0 m-0 fs-18 fw-300 font-san text-white text-center">
            Didn't get the text? Search for Chrono in your App Store.
          </p>
          <div className="d-flex mt-2 justify-content-center">
            <Image
              src={appleStore}
              className="pointer  py-1 mx-2"
              height="50px"
              onClick={() => linkRedirect(process.env.REACT_APP_IOS_LINK)}
            />
            <Image
              src={googleStore}
              className="pointer  py-1 mx-2"
              height="50px"
              onClick={() => linkRedirect(process.env.REACT_APP_IOS_LINK)}
            />
          </div>
        </div>
      </Col>
      <Col md="12" className="text-center pt-3">
        <Button
          variant="link"
          className="text-white shadow-none fw-400 font-san fs-18"
          onClick={() => backToLogin()}
        >
          Back to HomePage
        </Button>
      </Col>
    </Row>
  );
};
export default SevenStep;
