import { Button, Form, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import mail from "../../assets/img/email.svg";
import { Storage } from "../../helpers";
import * as onboardService from "../../services/onboard";
import * as alertActions from "../../actions/alert";
import { useDispatch } from "react-redux";
import { nextFieldInput } from "../../helpers/utility";

const ThirdStep = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Submit OTP
  const onSubmit = (e) => {
    let data = {};
    if(+sessionStorage.getItem("step") === 3){
      const userData = JSON.parse(sessionStorage.getItem('userData')) || null;
      data = {
        email: userData !== null ? userData.email : "",
        password: userData !== null ?  userData.password : "",
        otp:
          e["passcode-1"] +
          e["passcode-2"] +
          e["passcode-3"] +
          e["passcode-4"] +
          e["passcode-5"] +
          e["passcode-6"],
      };
    }
    onboardService.verify(data).then((response) => {
      if (response.status_code === 200 && response.data) {
        Storage.setSessionRegister(response.data);
        dispatch(alertActions.success("Email verification was successful."));
        setTimeout(() => {
          sessionStorage.setItem('token' , JSON.stringify(response.data.tokens.access));
          sessionStorage.setItem('user' , JSON.stringify(response.data.user));
          sessionStorage.setItem('step' , 4);
          props.nextStep();
        }, 1000);
      } else if (response.status_code !== 200) {
        sessionStorage.setItem('step' , 3);
        dispatch(alertActions.error(response.error));
      }
    });
  };

  const resendOTP = () => {
    let data = {};
    if(+sessionStorage.getItem("step") === 3){
      const userData = JSON.parse(sessionStorage.getItem('userData')) || null;
      console.log(userData)
      data = {
        email: userData !== null ?  userData.email : "",
        password: userData !== null ? userData.password : "",
    }
  }
    onboardService.signup(data).then((response) => {
      if (response.status_code === 200) {
        dispatch(
          alertActions.success("Passcode sent successfully on your email id.")
        );
      }else if(response.status_code !== 200){
        dispatch(alertActions.error("No authentication."));
      }
    });
  };

  return (
    <div
      style={{ marginTop: "-93px" }}
      className="d-flex flex-column min-vh-100 justify-content-center align-items-center g-0 "
    >
      <div
        className="bg-white shadow border px-4 br-15 border shadow-sm mt-5 py-5 font-san"
        style={{ maxWidth: "450px" }}
      >
        <Image src={mail} className="img-fluid d-block mx-auto " />
        <div className="py-2 text-center">
          <h5 className="fw-300 fs-20 pt-3 pb-2">Check your Email!</h5>
          <p className="fw-400 text-muted fs-18">
            Please check your Email and enter the 6 digit passcode to verify
            your Chrono account.
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <div className="d-flex mx-md-5">
                <Form.Control
                  className={
                    errors.one !== undefined
                      ? "w-25 border-0 border-bottom border-danger mx-2 text-center outline-0 fw-600 shadow-none br-0 br-0"
                      : "w-25 border-0 border-bottom mx-2 text-center outline-0 fw-600 shadow-none br-0 br-0"
                  }
                  minLength="1"
                  maxLength="1"
                  onKeyUp={(e) => nextFieldInput(e)}
                  {...register("passcode-1", {
                    required: true,
                  })}
                />
                <Form.Control
                  className={
                    errors.one !== undefined
                      ? "w-25 border-0 border-bottom border-danger mx-2 text-center outline-0 fw-600 shadow-none br-0 br-0"
                      : "w-25 border-0 border-bottom mx-2 text-center outline-0 fw-600 shadow-none br-0 br-0"
                  }
                  minLength="1"
                  maxLength="1"
                  onKeyUp={(e) => nextFieldInput(e)}
                  {...register("passcode-2", {
                    required: true,
                  })}
                />
                <Form.Control
                  className={
                    errors.one !== undefined
                      ? "w-25 border-0 border-bottom border-danger mx-2 text-center outline-0 fw-600 shadow-none br-0"
                      : "w-25 border-0 border-bottom mx-2 text-center outline-0 fw-600 shadow-none br-0"
                  }
                  minLength="1"
                  maxLength="1"
                  onKeyUp={(e) => nextFieldInput(e)}
                  {...register("passcode-3", {
                    required: true,
                  })}
                />
                <Form.Control
                  className={
                    errors.one !== undefined
                      ? "w-25 border-0 border-bottom border-danger mx-2 text-center outline-0 fw-600 shadow-none br-0"
                      : "w-25 border-0 border-bottom mx-2 text-center outline-0 fw-600 shadow-none br-0"
                  }
                  minLength="1"
                  maxLength="1"
                  onKeyUp={(e) => nextFieldInput(e)}
                  {...register("passcode-4", {
                    required: true,
                  })}
                />
                <Form.Control
                  className={
                    errors.one !== undefined
                      ? "w-25 border-0 border-bottom border-danger mx-2 text-center outline-0 fw-600 shadow-none br-0"
                      : "w-25 border-0 border-bottom mx-2 text-center outline-0 fw-600 shadow-none br-0"
                  }
                  minLength="1"
                  maxLength="1"
                  onKeyUp={(e) => nextFieldInput(e)}
                  {...register("passcode-5", {
                    required: true,
                  })}
                />
                <Form.Control
                  className={
                    errors.one !== undefined
                      ? "w-25 border-0 border-bottom border-danger mx-2 text-center outline-0 fw-600 shadow-none br-0"
                      : "w-25 border-0 border-bottom mx-2 text-center outline-0 fw-600 shadow-none br-0"
                  }
                  minLength="1"
                  maxLength="1"
                  onKeyUp={(e) => nextFieldInput(e)}
                  {...register("passcode-6", {
                    required: true,
                  })}
                />
              </div>
            </Form.Group>
            <div className="mb-3">
              <p
                className="py-3 fs-14 fw-300 text-danger pointer"
                onClick={() => resendOTP()}
              >
                Resend Passcode
              </p>
              <Button
                type="submit"
                variant="sidebar"
                className="px-5 rounded-pill fs-18 fw-400"
              >
                Verify & Proceed
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ThirdStep;
