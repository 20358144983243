import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState, useCallback } from "react";
import worldMap from "@highcharts/map-collection/countries/us/us-all.geo.json";
import highchartsMap from "highcharts/modules/map";
import * as statsServices from "../../services/stats";
import { useParams } from "react-router-dom";
import { isExpiredWithToken } from "../../helpers/auth.helper";
import { history } from "../../helpers";

highchartsMap(Highcharts);

const AmericanState = () => {
  const { token } = useParams();
  const [usaData, setUsaData] = useState([]);
  const urlParams = new URLSearchParams(history.location.search);
  const year = urlParams.get("year");

  const getDataFromAPI = useCallback(
    (i) => {
      const headers = { Authorization: `Bearer ${i}` };
      const startYear = `${
        year !== undefined && year !== null  ? year : new Date().getFullYear()
      }-01-01T00:01:00.000Z`;
      const endYear = `${
        year !== undefined && year !== null ? year : new Date().getFullYear()
      }-12-31T23:59:59.000Z`;
      statsServices
        .getVisitedUSAPlacesWithHeader("REGION", startYear, endYear, headers)
        .then((response) => {
          if (
            response.status_code === 200 &&
            response.data.records.length > 0
          ) {
            setUsaData(response.data.records);
          }
        });
    },
    [year]
  );

  useEffect(() => {
    if (token !== undefined && !isExpiredWithToken(token)) {
      getDataFromAPI(token);
    }
  }, [getDataFromAPI, token]);

  return token !== undefined && !isExpiredWithToken(token) ? (
    <Region data={usaData} />
  ) : (
    <p>You don't have access this page...</p>
  );
};

export default AmericanState;

const Region = ({ data }) => {
  const options = {
    title: {
      text: "",
    },
    chart: {
      spacingLeft: 5,
      spacingRight: 5,
      spacingTop: 5,
      spacingBottom: 5,
      height: 350,
      type: "map",
      map: worldMap,
      className: "globalChart",
    },
    xAxis: {
      tickPixelInterval: 50,
    },
    yAxis: {
      title: null,
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      series: {
        showInLegend: false,
        color: "#8FD0FF",
        label: {
          enabled: false,
        },
        name: "Visited Places",
        marker: {
          enabled: false,
        },
        threshold: 0,
      },
    },
    mapNavigation: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Basemap",
        mapData: worldMap,
        borderColor: "#606060",
        nullColor: "rgba(200, 200, 200, 0.2)",
        showInLegend: false,
      },
      {
        data,
      },
    ],
    exporting: {
      enabled: false,
    },
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"mapChart"}
      options={options}
    />
  );
};
