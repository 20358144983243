import { Spinner } from "react-bootstrap";

const LoaderInline = ({ loader, borderRadius }) => {
  return (
    loader && (
      <div
        className="loader-inline position-absolute w-100 h-100 top-0 left-0 d-flex justify-content-center align-items-center "
        style={
          !borderRadius && borderRadius !== undefined ? { borderRadius: 0 } : {}
        }
      >
        <Spinner animation="grow" variant="dark" />
      </div>
    )
  );
};

export default LoaderInline;
