const MultipleHomesIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="60"
      height="45"
      viewBox="0 0 60 61"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25004 14.3636C0.918902 14.3636 0.587761 14.2295 0.343348 13.9694C-0.129709 13.4648 -0.113941 12.6763 0.390654 12.2033L12.903 0.345301C13.3997 -0.127756 14.196 -0.111988 14.6691 0.392607C15.1422 0.897202 15.1264 1.68563 14.6218 2.15869L2.10943 14.0167C1.8729 14.2453 1.55753 14.3636 1.25004 14.3636Z"
        fill="currentColor"
      />
      <path
        d="M26.2825 14.1571C25.975 14.1571 25.6675 14.0467 25.431 13.8181L12.9107 2.1651C12.4061 1.69204 12.3746 0.903609 12.8477 0.399015C13.3207 -0.10558 14.1091 -0.137117 14.6137 0.33594L27.1261 11.9889C27.6307 12.462 27.6622 13.2504 27.1892 13.755C26.9527 14.0231 26.6136 14.1571 26.2825 14.1571Z"
        fill="currentColor"
      />
      <path
        d="M22.3334 27.5373H17.0667C16.3728 27.5373 15.8131 26.9775 15.8131 26.2837V16.996H11.729V26.2837C11.729 26.9775 11.1692 27.5373 10.4754 27.5373H5.20868C4.51486 27.5373 3.95508 26.9775 3.95508 26.2837V9.36397C3.95508 8.67016 4.51486 8.11037 5.20868 8.11037C5.9025 8.11037 6.46228 8.67016 6.46228 9.36397V25.0301H9.22967V15.7424C9.22967 15.0485 9.78945 14.4888 10.4833 14.4888H17.0667C17.7605 14.4888 18.3203 15.0485 18.3203 15.7424V25.0301H21.0876V3.89228C21.0876 3.19846 21.6474 2.63867 22.3412 2.63867C23.0351 2.63867 23.5948 3.19846 23.5948 3.89228V26.2837C23.5791 26.9775 23.0193 27.5373 22.3334 27.5373Z"
        fill="currentColor"
      />
      <path
        d="M33.7247 46.8372C33.3935 46.8372 33.0624 46.7032 32.818 46.443C32.3449 45.9384 32.3607 45.15 32.8653 44.6769L45.3776 32.8189C45.8743 32.3459 46.6707 32.3616 47.1437 32.8662C47.6168 33.3708 47.601 34.1593 47.0964 34.6323L34.584 46.4903C34.3475 46.7189 34.0321 46.8372 33.7247 46.8372Z"
        fill="currentColor"
      />
      <path
        d="M58.7571 46.6327C58.4496 46.6327 58.1421 46.5223 57.9056 46.2937L45.3853 34.6407C44.8807 34.1676 44.8492 33.3792 45.3223 32.8746C45.7953 32.37 46.5838 32.3385 47.0884 32.8115L59.6007 44.4645C60.1053 44.9376 60.1369 45.726 59.6638 46.2306C59.4273 46.4987 59.0882 46.6327 58.7571 46.6327Z"
        fill="currentColor"
      />
      <path
        d="M54.7982 60.005H49.5315C48.8377 60.005 48.2779 59.4452 48.2779 58.7514V49.4716H44.1938V58.7593C44.1938 59.4531 43.634 60.0129 42.9402 60.0129H37.6735C36.9797 60.0129 36.4199 59.4531 36.4199 58.7593V41.8396C36.4199 41.1458 36.9797 40.586 37.6735 40.586C38.3673 40.586 38.9271 41.1458 38.9271 41.8396V57.5057H41.6945V48.218C41.6945 47.5242 42.2543 46.9644 42.9481 46.9644H49.5315C50.2253 46.9644 50.7851 47.5242 50.7851 48.218V57.5057H53.5525V36.36C53.5525 35.6662 54.1123 35.1064 54.8061 35.1064C55.4999 35.1064 56.0597 35.6662 56.0597 36.36V58.7514C56.0518 59.4452 55.492 60.005 54.7982 60.005Z"
        fill="currentColor"
      />
      <path
        d="M56.4479 31.2269C55.7541 31.2269 55.1943 30.6671 55.1943 29.9733C55.1943 16.0654 43.8803 4.74353 29.9645 4.74353C29.2707 4.74353 28.7109 4.18375 28.7109 3.48993C28.7109 2.79611 29.2707 2.23633 29.9645 2.23633C45.2522 2.23633 57.6936 14.6777 57.6936 29.9654C57.7015 30.6671 57.1417 31.2269 56.4479 31.2269Z"
        fill="currentColor"
      />
      <path
        d="M29.9732 57.7029C14.6856 57.7029 2.24414 45.2615 2.24414 29.9738C2.24414 29.28 2.80393 28.7202 3.49774 28.7202C4.19156 28.7202 4.75135 29.28 4.75135 29.9738C4.75135 43.8817 16.0653 55.2035 29.9811 55.2035C30.6749 55.2035 31.2347 55.7633 31.2347 56.4572C31.2347 57.151 30.6591 57.7029 29.9732 57.7029Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default MultipleHomesIcon;
