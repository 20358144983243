import { Storage } from ".";
import * as moment from "moment";
import { Image } from "react-bootstrap";

export const handleApi = async (promise) => {
  return promise
    .then((result) => result.data)
    .catch((error) => Promise.resolve(error.response.data));
};

export const fileType = () => {
  return "image/png, image/jpeg, image/svg , image/jpg , .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
};

// Password Pattern
export const passwordPattern =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&_*?])[A-Za-z\d#$@!%&_*?]{6,30}$/;

export const passwordCheck = (password) => {
  let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  return {
    length: password.length >= 8 && password.length <= 20,
    number: /\d/.test(password),
    uppercase: /^[A-Z]\w+/.test(password),
    special: format.test(password),
  };
};
export const formattedDate = (createdAt) => {
  const date1 = new Date(createdAt);
  const date2 = new Date();
  var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);

  return diffDays === 0
    ? "Today"
    : diffDays === 1
    ? "Yesterday"
    : moment(createdAt).format("Do MMMM, YYYY");
};

// Check for empty object
export const isEmpty = (obj) => {
  if (obj === null || obj === undefined) return false;
  return Object.keys(obj).length === 0;
};

export const sortData = (obj, param) => {
  const arr = [];
  obj.map((item) => arr.push({ label: item.name, value: item[param] }));
  return arr;
};

export const sortDataCountry = (obj) => {
  const arr = [];
  obj.map((item) => arr.push({ label: item.phonecode, value: item.phonecode }));
  return arr;
};

export const getSortData = (obj, key, value) => {
  return obj.filter((item) => item[key] === value)[0];
};

// Split Array in Different Arrays
export const splitArray = (arr, n) => {
  return new Array(Math.ceil(arr.length / n))
    .fill()
    .map((_) => arr.splice(0, n));
};

export const backToLogin = () => {
  Storage.removeAll();
  window.location.href = "https://www.gochrono.io/";
};

export const toggleButton = (value) => {
  return value
    ? "bg-sidebar d-flex justify-content-center text-white border-sidebar shadow-none rounded-pill px-5"
    : " px-5 shadow-none d-flex justify-content-center bg-white text-muted border-white rounded-pill";
};

export const getEndDate = (days) => {
  return new Date(
    new Date().setDate(new Date().getDate() + days)
  ).toISOString();
};

export const formatDate = (value) => {
  const formatDate = Storage.getSession() && Storage.getSession().dateFormat;
  return moment(value).format(formatDate);
};

export const monthsNames = (count = "") => {
  const arr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (count === "") return arr;
  if (count > 0)
    return arr.map((item) => {
      return item.slice(0, count).toUpperCase();
    });
};

export const generateArrayOfYears = (count = 3) => {
  var max = new Date().getFullYear();
  var min = max - count;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

export const downloadURI = (uri, name) => {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const nextFieldInput = (e) => {
  const { maxLength, value, name } = e.target;
  const [fieldName, fieldIndex] = name.split("-");

  let fieldIntIndex = parseInt(fieldIndex, 10);

  // Check if no of char in field == maxlength
  if (value.length >= maxLength) {
    // It should not be last input field
    if (fieldIntIndex < 6) {
      // Get the next input field using it's name
      const nextfield = document.querySelector(
        `input[name=${fieldName}-${fieldIntIndex + 1}]`
      );

      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
  } else if (value.length === 0) {
    var previous = e.target;
    while ((previous = previous.previousElementSibling)) {
      if (previous == null) break;
      if (previous.tagName.toLowerCase() === "input") {
        previous.focus();
        break;
      }
    }
  }
};

export const renderFlag = (name, countries) => {
  const country = countries.filter((obj) => obj.name === name)[0];
  return (
    <div className="d-flex">
      <div
        style={{
          width: "25px",
          height: "25px",
          display: "block",
          borderRadius: "20px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Image
          src={
            "https://chrono-dev.s3.amazonaws.com/ChronoPublic/flags/" +
            country.isoCode +
            ".PNG"
          }
          style={{
            width: "25px",
            height: "28px",
            position: "absolute",
            top: "-1px",
            left: "0px",
            transform: "scale(1.4)",
          }}
        />
      </div>
      <span className="px-2">{name}</span>
    </div>
  );
};

// export const formatTimezone = (arr) => {
//   let timeZone = [];
//   arr.forEach((item) => {
//     timeZone.push({
//       label: item.zoneName + " " + item.gmtOffsetName,
//       value: item.zoneName,
//       ...item,
//     });
//   });
//   return timeZone;
// };

export const indexOfMaxValue = (a) =>
  a.reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
