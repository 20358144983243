import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { auth } from "../reducers/auth";
import { loader } from "../reducers/loader";
import { alert } from '../reducers/alert';
import { stage } from "../reducers/stage";
import {goal } from "../reducers/goal";

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    loader,
    alert,
    stage,
    goal
  });
