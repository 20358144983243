import { alertConstants } from "../helpers/actionTypes";
let id = 1;

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        ...state,
        id: id++,
        type: 'success', 
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        ...state,
        id: id++,
        type: 'danger',
        message: action.message
      };

    case alertConstants.INFO:
        return {
          ...state,
          id: id++,
          type: 'info',
          message: action.message
        };
           
    case alertConstants.WARNING:
      return {
        ...state,
        id: id++,
        type: 'warning',
        message: action.message
      };

    case alertConstants.CLEAR:
      return {};
    default:
      return state
  }
}