import { ACCOUNT } from "../helpers/actionTypes";

const initState = {
  isFetching: false,
  authenticated: false,
  loginData: {},
  error: {},
};

export function auth(state, action) {
  if (!state) state = initState;
  let authData = {};
  if (action.payload) authData = action.payload.data;
  switch (action.type) {
    case ACCOUNT.LOGIN.REQUEST:
    case ACCOUNT.LOGIN.SUCCESS:
      return {
        ...state,
        authenticated: true,
        loginData: authData.data,
        isFetching: false,
      };
    case ACCOUNT.LOGIN.FAILURE:
      return {
        ...state,
        authenticated: false,
        isFetching: false,
        error: action.error.response.data.error,
      };
    case ACCOUNT.AUTO_LOGIN:
      return {
        ...state,
        authenticated: true,
        loginData: action.data,
        isFetching: false,
      };
    case ACCOUNT.LOGOUT.REQUEST:
    case ACCOUNT.LOGOUT.SUCCESS:
      return {
        ...state,
        loginData: authData.data,
        authenticated: false,
      };
    case ACCOUNT.LOGOUT.FAILURE:
      return {
        ...state,
        authenticated: false,
        isFetching: false,
        error: action.error.response.data.error,
      };
    default:
      return state;
  }
}
