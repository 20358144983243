const LoveDataIcon = ({className , size}) => {
  return (
<svg 
className={className}  
width={size !== undefined && size ? '50px' : '35px'}
height={size !== undefined && size ? '40px' : '22px'} 
viewBox="0 0 35 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6627 22.4176C19.6674 22.4176 18.0391 20.7893 18.0391 18.794C18.0391 16.7987 19.6674 15.1704 21.6627 15.1704C23.658 15.1704 25.2863 16.7987 25.2863 18.794C25.2863 20.7893 23.6694 22.4176 21.6627 22.4176ZM21.6627 16.191C20.2293 16.191 19.0711 17.3606 19.0711 18.7826C19.0711 20.2045 20.2408 21.3741 21.6627 21.3741C23.0846 21.3741 24.2542 20.2159 24.2542 18.7826C24.2542 17.3492 23.0961 16.191 21.6627 16.191Z" fill="currentColor"/>
<path d="M12.649 17.9457C10.6537 17.9457 9.02539 16.3174 9.02539 14.3221C9.02539 12.3268 10.6537 10.6985 12.649 10.6985C14.6443 10.6985 16.2726 12.3268 16.2726 14.3221C16.2726 16.3174 14.6443 17.9457 12.649 17.9457ZM12.649 11.7191C11.2156 11.7191 10.0574 12.8772 10.0574 14.3106C10.0574 15.744 11.2156 16.9022 12.649 16.9022C14.0824 16.9022 15.2406 15.744 15.2406 14.3106C15.2406 12.8887 14.0709 11.7191 12.649 11.7191Z" fill="currentColor"/>
<path d="M3.62361 27.3705C1.62833 27.3705 0 25.7422 0 23.7469C0 21.7516 1.62833 20.1233 3.62361 20.1233C5.61889 20.1233 7.24723 21.7516 7.24723 23.7469C7.24723 25.7422 5.63036 27.3705 3.62361 27.3705ZM3.62361 21.1439C2.19022 21.1439 1.03204 22.3135 1.03204 23.7354C1.03204 25.1574 2.19022 26.3385 3.62361 26.3385C5.05701 26.3385 6.21519 25.1803 6.21519 23.7469C6.21519 22.3135 5.05701 21.1439 3.62361 21.1439Z" fill="currentColor"/>
<path d="M30.6881 7.24723C28.6928 7.24723 27.0645 5.6189 27.0645 3.62361C27.0645 1.62833 28.6928 0 30.6881 0C32.6833 0 34.3117 1.62833 34.3117 3.62361C34.3117 5.6189 32.6833 7.24723 30.6881 7.24723ZM30.6881 1.02057C29.2547 1.02057 28.0965 2.17876 28.0965 3.61215C28.0965 5.04554 29.2547 6.20372 30.6881 6.20372C32.1215 6.20372 33.2796 5.04554 33.2796 3.61215C33.2796 2.17876 32.1215 1.02057 30.6881 1.02057Z" fill="currentColor"/>
<path d="M10.1236 16.2125L5.40625 21.1421L6.1519 21.8556L10.8693 16.9261L10.1236 16.2125Z" fill="currentColor"/>
<path d="M15.6186 15.2207L15.1602 16.1453L18.3244 17.7141L18.7828 16.7895L15.6186 15.2207Z" fill="currentColor"/>
<path d="M28.6507 6.02521L22.8086 15.8528L23.6957 16.3802L29.5379 6.55258L28.6507 6.02521Z" fill="currentColor"/>
</svg>

  )
}

export default LoveDataIcon;