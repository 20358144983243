const LeftArrow = ({ className }) => {
  return (
    <svg
      width="38"
      height="38"
      className={className}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="18.5" stroke="white" />
      <path
        d="M21.3333 12.9998L15 19.3332L21.3333 25.6665"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default LeftArrow;
