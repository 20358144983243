import { handleApi } from "../helpers/utility";
import axiosClient from "../helpers/axiosClient";

const BASE = "stats";

export async function getBasicStats(from, to) {
  let filters = "";
  if (from !== undefined && to !== undefined) {
    filters = "from=" + from + "&to=" + to;
  }
  return await handleApi(
    axiosClient.get(`${BASE}${filters !== "" ? "?" + filters : ""}`)
  );
}

export async function getVisitedPlaces(type, from = "", to = "") {
  return await handleApi(
    axiosClient.get(
      `${BASE}/visited-places?filterBy=${"COUNTRY"}${
        from !== "" && to !== "" ? "&from=" + from + "&to=" + to : ""
      }`
    )
  );
}

export async function getGlobalTravel(filter) {
  return await handleApi(
    axiosClient.get(`${BASE}/global-travel?filterBy=${filter}`)
  );
}

export async function getGlobalTravelWithToken(filter, headers) {
  return await handleApi(
    axiosClient.get(`${BASE}/global-travel?filterBy=${filter}`, { headers })
  );
}

export async function getVisitedUSAPlaces(filter) {
  return await handleApi(
    axiosClient.get(`${BASE}/visited-american-places?filterBy=${filter}`)
  );
}

export async function getVisitedUSAPlacesWithHeader(filter, to, from, headers) {
  return await handleApi(
    axiosClient.get(
      `${BASE}/visited-american-places?filterBy=${filter}&to=${to}&from=${from}`,
      {
        headers,
      }
    )
  );
}
export async function getTravelPattern(from = "", to = "") {
  return await handleApi(
    axiosClient.get(
      `${BASE}/travel-pattern${
        from !== "" && to !== "" ? "?from=" + from + "&to=" + to : ""
      }`
    )
  );
}

export async function getBucketList() {
  return await handleApi(axiosClient.get(`${BASE}/bucket-list`));
}

export async function bucketPatch(data) {
  return await handleApi(axiosClient.patch(`${BASE}/bucket-list`, data));
}
