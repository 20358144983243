import { Button, Col, Form, Row } from "react-bootstrap";
import * as userService from "../../services/user";
import * as alertActions from "../../actions/alert";
import * as loaderActions from "../../actions/loader";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { Storage } from "../../helpers";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js/min";

const FourthStep = (props) => {
  const dispatch = useDispatch();
  const userData = JSON.parse(sessionStorage.getItem('user'));
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: userData !== null && userData.firstName !== null ? userData.firstName : "" ,
      lastName:  userData !== null && userData.lastName !== null ? userData.lastName : "",
      mobile:  userData !== null && userData.countryCode+""+userData.mobile !== null ? userData.countryCode+""+userData.mobile : ""
    }
  });
  const [actionStart, setActionStart] = useState(false);

  const onSubmit = (e) => {
    let number;
    if(!e.mobile.startsWith('+')){
       number = "+" + e.mobile;
    }else {
      number = e.mobile;
    }
  
    if (isValidPhoneNumber(number)) {
      dispatch(loaderActions.loader(true));
      setActionStart(true);
      props.setMobile(number);
      const phoneParse = parsePhoneNumber(number);
      props.setName(e.firstName);
      const data = {
        countryCode: "+" + phoneParse.countryCallingCode,
        firstName: e.firstName,
        lastName: e.lastName,
        mobile: phoneParse.nationalNumber,
      };
      userService.accountUpdate(data).then((response) => {
        if (response.status_code === 200) {
          dispatch(alertActions.success("Your profile info. has been saved."));
          Storage.updateSession(response.data);
          sessionStorage.setItem('user',  JSON.stringify(response.data));
          setTimeout(() => {
            sessionStorage.setItem('step' , 5);
            props.nextStep();

          }, 1000);
          setActionStart(false);
        } else if (response.status_code !== 200) {
          sessionStorage.setItem('step' , 4);
          dispatch(alertActions.error(response.error));
          setActionStart(false);
        }
        dispatch(loaderActions.loader(false));
      });
    } else {
      dispatch(alertActions.error("Phone Number is not valid."));
    }
  };

  return (
    <Row
      style={{ marginTop: "-90px" }}
      className="d-flex flex-column min-vh-100 justify-content-center align-items-center g-0 "
    >
      <Row className="login-form bg-white mt-5 br-20">
        <Col md="12" className="text-center my-5 pt-2 pb-4 ">
          <h2 className="text-chronoOrange fw-600 ">Profile info</h2>
        </Col>
        <Col
          md="12"
          sm="12"
          className="d-flex justify-content-center flex-column"
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ width: "300px" }} className="mx-auto">
              <Form.Group className="mb-4 pb-2">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  className="bg-none border-0 border-bottom shadow-none border-dark text-dark br-0 px-0 py-2 custom-input fs-18 fw-300 font-san"
                  {...register("firstName", {
                    required: "First Name is required.",
                  })}
                />
                {errors.firstName && errors.firstName.type === "required" && (
                  <div className="required text-danger fs-14 font-san">
                    First Name is required.
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4 pb-2">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  className="bg-none border-0 border-bottom shadow-none border-dark text-dark br-0 px-0 py-2 custom-input fs-18 fw-300 font-san"
                  {...register("lastName", {
                    required: "Last Name is required.",
                  })}
                />
                {errors.lastName && errors.lastName.type === "required" && (
                  <div className="required text-danger fs-14 font-san">
                    Last Name is required.
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4 pb-2">
                <Controller
                  control={control}
                  name="mobile"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      name="mobile"
                      containerClass="react-tel-input-black"
                      inputClass="fw-300"
                      placeholder="Phone Number"
                      value={field.value}
                      country={
                        Storage.getSelectedCountry() !== null
                          ? Storage.getSelectedCountry().isoCode.toLowerCase()
                          : "us"
                      }
                      autoFormat={true}
                      enableSearch={true}
                      disableSearchIcon={true}
                      onChange={(phone) => field.onChange(phone)}
                    />
                  )}
                />
                {errors.mobile && errors.mobile.type === "required" && (
                  <div className="required text-danger fs-14 font-san">
                    Mobile Number is Required.
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between py-5  mx-auto px-2">
              <Button
                className="btn-clear text-pureOrange shadow-none bg-none fw-300 arrow-left border-0"
                onClick={() =>
                  dispatch(alertActions.error("You can't go back."))
                }
              ></Button>
              <Button
                type="submit"
                className="btn-clear text-pureOrange shadow-none bg-none fw-300 arrow-right border-0"
                disabled={actionStart}
              ></Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Row>
  );
};
export default FourthStep;
